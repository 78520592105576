const validParams = ['tabIndex', 'location', 'selectedCoverageYear'];
const safeInvalidParams = ['_gl', '_gcl_au'];

const validateQueryParams = (searchParams: URLSearchParams) => {
    if (!Array.from(searchParams.keys()).length)
        return true; // No params

    for (const key of validParams) {
        if (!searchParams.get(key)) {
            return false; // searchParams does not contain any of the required params
        }
    }

    return true; // searchParams contains all required params
};

const clearQueryParams = (searchParams: URLSearchParams) => {
    let isModified = false;

    safeInvalidParams.forEach(param => {
        if (searchParams.has(param)) {
            searchParams.delete(param);
            isModified = true;
        }
    });

    return { isModified, searchParams };
};

export { validateQueryParams, clearQueryParams}
