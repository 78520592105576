import React, { FC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ITooltipType } from '../models/TooltipType';
import useTrackEvent from "../../common/utils/useTrackEvent";
import { AuditEventTypes } from "../../common/utils/TrackEventTypes";

const TooltipContainer: FC<ITooltipType> = ({ content, children, disableHover, eventDetails }) => {
    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchToolTipClicked,
        eventDetails
    );

    return (
        //here for some reason the react.fragment doesnt work, pls dont change it (I'm talking about the children prop)
        <Tooltip
            title={<>{content}</>}
            placement="top-start"
            arrow
            enterTouchDelay={0}
            disableHoverListener={disableHover}
            onOpen={trackEvent}
        >
            <div className='tooltip__container'>
                {children}
            </div>
        </Tooltip>
    )
}

export default TooltipContainer