import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updateQuickSearch, resetIndexPage, quickSearch } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { disclosureText } from '../../common/filters/utils';
import { ButtonContainer, } from "../../components";
import Disclosure from '../Common/Disclosure/Disclosure';
import LocationField from "../Fields/LocationField";
import ProviderNameField from "../Fields/ProviderNameField";
import { needResetIndexPage, scrollDown } from "../utils";
import './QuickSearchContainer.scss';
import useTrackEvent from '../../common/utils/useTrackEvent';
import { AuditEventTypes, Menus } from '../../common/utils/TrackEventTypes';

const QuickSearchContainer = () => {
    const providerData = providerSelector;
    const showClearBtn = useSelector(providerData.showResults);
    const searchTerm = useSelector(providerData.searchTerm);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const indexPage = useSelector(providerData.indexPage);
    const [disabledForm, setDisabledForm] = useState(true);
    const dispatch = useDispatch();

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchQuickSearchButtonClicked,
        {
            menu: Menus.QuickSearch,
            searchParameters: {
                location: selectedLocation,
                searchTerm,
            }
        }
    );

    useEffect(() => {
        validateProviderName();
    }, [searchTerm]);

    const handleClearSearch = () => {
        dispatch(updateQuickSearch({
            quickSearch: {
                location: "",
                searchTerm: "",
                showResults: 0
            }
        }))
    }

    const handleSubmit = () => {
        trackEvent();
        dispatch(quickSearch(2));
        if (needResetIndexPage(indexPage)) {
            dispatch(resetIndexPage('quickSearch'))
        }

        scrollDown(800);
    }

    const validateProviderName = () => {
        if (searchTerm.length < 3 || searchTerm.startsWith(" "))
            setDisabledForm(true);
        else
            setDisabledForm(false);
    }

    const disclosureContent = <p>{disclosureText.quickSearch}</p>

    return (
        <>
            <div className='providertypes second-tab'>
                <Disclosure content={disclosureContent} />

                <ProviderNameField fieldText='Search Term*' />

                <LocationField isRequired={false} />

                <ButtonContainer
                    className='fieldgroup__submit'
                    placeholder='Search'
                    onClick={handleSubmit}
                    disabled={disabledForm}
                />

                <ButtonContainer
                    className='fieldgroup__clear'
                    placeholder='Clear search'
                    onClick={handleClearSearch}
                    disabled={showClearBtn !== 2}
                />
            </div>
        </>
    )
}

export default QuickSearchContainer;