import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters, getProviderServices } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt } from "../../common/filters";
import { DropdownContainer } from "../../components";

const ServicesField = () => {
    const selector = providerSelector;
    const servicesData = useSelector(selector.servicesTypeData);
    const selectedService = useSelector(selector.selectedServiceType);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: String) => {
        const service = newValue;
        dispatch(updateFilters({ selectedServiceType: service }));
    }

    useEffect(() => {
        if (!servicesData) {
            dispatch(getProviderServices())
        }
    }, [servicesData])

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    Services
                </div>
            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'ServiceDropdown'}
                    className="fieldgroup__select"
                    dataSource={servicesData}
                    placeholder={placeholdersTxt.default}
                    onChange={handleChange}
                    value={selectedService}
                    dataIsAnObject={false}
                    enabled={false}
                />
            </div>
        </div>
    )
}

export default ServicesField;