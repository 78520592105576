import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ILinkContainerType } from '../models/LinkContainerType';
import useTrackEvent from '../../common/utils/useTrackEvent';

const InternalLinkContainer: FC<ILinkContainerType> = ({className, placeholder, urlAddress, eventName }) => {
    const trackEvent = useTrackEvent(eventName, {});

    const handleClick = () => {
        if (eventName) {
            trackEvent();
        }
    }

    return (
        <Link className={className} to={urlAddress} role="link" onClick={handleClick}>{placeholder}</Link>
    )
}

export default InternalLinkContainer;