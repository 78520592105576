import React, { FC } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import { ProviderIcon } from "../Icons/SvgIcons";
import { getSpecialities } from "../../common/widgets";
import AcceptingConditions from "../AcceptingConditions/AcceptingConditions";
import { IMedicalGroupsPreviewType } from "../models/MedicalGroupsPreviewType";
import "./MedicalGroupsPreview.scss";

const MedicalGroupsPreview: FC<IMedicalGroupsPreviewType> = ({
    provider,
    onCloseHandler,
}) => {
    const pcpSpecialities = getSpecialities(provider?.pcpSpecialities || []);

    return (
        <div className="medicalGroupPreview__container">
            <div className="header__container">
                <div className="icon__container addIcon__container">
                    {ProviderIcon['medicalGroup']}
                    <span className="groups__length">{provider?.medicalGroups.length}</span>
                </div>
                <div className="title__container">
                    <span>Medical Groups</span>
                </div>
                <div className="icon__container">
                    <button className="buttonIcon" onClick={onCloseHandler}>
                        <CancelIcon sx={{ fontSize: 38.54 }} />
                    </button>
                </div>
            </div>
            <div className="medicalGroupList__container">
                <ul className="medicalGroupList">
                    {provider?.medicalGroups &&
                        provider?.medicalGroups.map((group: any, index: any) => {
                            return (
                                <li key={`provider${provider?.npi}group${index}`}>
                                    <div className="medicalGroupDetail__container">
                                        <div className="groupName__container">
                                            <span>{group.name}</span>
                                        </div>
                                        <div className="groupId__container">
                                            <span className="groupProviderId__label">
                                                {"Provider ID: "}
                                            </span>
                                            <span className="groupProviderId__text">
                                                {group.providerID}
                                            </span>
                                        </div>
                                        {provider?.isPCP && (
                                            <div className="groupPCP__container">
                                                <span className="groupProviderPCP__label">
                                                    {"Provider PCP: "}
                                                </span>
                                                <span className="groupProviderPCP__text">
                                                    {pcpSpecialities}
                                                </span>
                                            </div>
                                        )}
                                        <div className="groupAcceptingConditions__container">
                                            <AcceptingConditions
                                                locations={provider?.locations}
                                                newPatients={provider?.acceptingNewPatients}
                                            />
                                        </div>
                                    </div>
                                    <Divider role="presentation" />
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default MedicalGroupsPreview;
