import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from '@mui/material'
import Autocomplete from "react-google-autocomplete";
import { placeholdersTxt } from "../../common/filters";
import { DialogContainer, ButtonContainer, Icon } from "../../components";
import PSMap from "../Map/PSMap";
import { ILocationType } from "../models/LocationType";
import './GetDirections.scss'


const GetDirections: FC<ILocationType> = (locations) => {

    const [showModal, setShowModal] = useState(false);
    const [listDirecitons, setListDirections] = useState(null);
    const [fromWhere, setFromWhere] = useState('');
    const [submit, setSubmit] = useState(false);
    const locationRef: any = useRef();

    const handleModal = () => {
        setShowModal(!showModal)
    }

    const handleSubmit = () => {
        setSubmit(!submit);
    }

    const getDirections = (response: any) => {
        setListDirections(response['routes'][0]['legs'][0]['steps'])
    }

    const listOfStep = (list: any) => {
        return (
            <ul>
                {
                    list.map((item: any, index: number) => {
                        return (
                            <li key={index}>
                                {directionToIcon(item['maneuver'])}
                                <div className="get-directions__instruction">{item['maneuver']} {item['distance']['text']}</div>
                                <div className="get-directions__address" dangerouslySetInnerHTML={stringParcertToHtml(item['instructions'])}></div>
                            </li>
                        )
                    })
                }
            </ul>)
    }

    const directionToIcon = (instructions: string) => {
        switch (instructions) {
            case '':
            case 'straight':
                return (
                    <div><Icon icon={'go'} /></div>
                )
            case 'turn-right':
            case 'keep-right':
                return (
                    <div><Icon icon={'right'} /></div>
                )
            case 'turn-left':
            case 'keep-left':
                return (
                    <div><Icon icon={'left'} /></div>
                )
            case 'fork-right':
            case 'fork-left':
                return (
                    <div><Icon icon={'left'} /></div>
                )
            case 'ramp-right':
            case 'ramp-left':
                return (
                    <div><Icon icon={'merge'} /></div>
                )
        }
    }

    const stringParcertToHtml = (value: string) => {
        return { __html: value }
    }

    const headerTemplate = () => {
        return (
            <div id="#printSection" className='dialog__header'>
                <div className='dialog__header-title'>
                    Route to {locations.addressLine1}
                </div>
                <button
                    className='dialog__header-btn'
                    onClick={handleModal}
                > X </button>
            </div>
        )
    };

    const handlePlaceSelected = (event: any) => {
        setFromWhere(event.formatted_address)
    }

    const HandleContent = () => {
        useEffect(() => {
            if (showModal) {
                //I need to create this timeout just to wait the element is redered and then change the attribute
                setTimeout(() => {
                    const myInput = document.getElementById('location-text-box-directions') as HTMLInputElement;
                    if (myInput) {
                        myInput.autocomplete = "off"
                    }
                }, 200)
            }
            return () => {
                setFromWhere('');
                setListDirections(null)
                setSubmit(false)
            }
        }, [showModal])
        const markers = [{ latitude: locations.latitude, longitude: locations.longitude, title: locations.addressLine1 }];

        return (
            <div className={`get-directions__content ${(submit && fromWhere !== '') ? 'get-directions__success' : ''}`}>
                <div className="fieldgroup__container">
                    <div className="fieldgroup__field">
                        <Autocomplete
                            ref={locationRef}
                            className="fieldgroup__field-input"
                            id="location-text-box-directions"
                            onPlaceSelected={handlePlaceSelected}
                            placeholder={placeholdersTxt.location}
                            aria-label="Search by zip code or location"
                            options={{
                                types: ['postal_code', 'street_address', 'locality'],
                                componentRestrictions: { country: "us" }
                            }}
                            defaultValue={fromWhere}
                            apiKey={'AIzaSyCZUXjld2wi_EmRj5H4Y6idtjx5lkvrjn4'}
                        />
                    </div>
                    <ButtonContainer
                        className='dialog__container-btn'
                        placeholder='Search'
                        onClick={handleSubmit}
                        disabled={fromWhere === ''}
                    />
                </div>
                {
                    submit && (
                        <>
                            <div className='get-directions__map'>
                                <PSMap providerMarkers={markers} needDirections={true} getDirections={getDirections} originPoint={fromWhere} />
                            </div>
                            <div className="get-directions__steps">
                                <div className="get-directions__header">
                                    <h2>
                                        How to get there
                                    </h2>
                                </div>

                                {
                                    listDirecitons && (listOfStep(listDirecitons))
                                }
                            </div>
                        </>
                    )
                }
            </div>

        )
    };

    const handlePrint = () => {
        const printContent1 = document.getElementById("root");

        if (printContent1) {
            printContent1.style.visibility = 'hidden';

            window.print();
            printContent1.style.visibility = 'visible';
        }
    }

    const footerTemplate = () => {
        return (
            <>
                <div className="report-information__footer">
                    <ButtonContainer
                        className='dialog__container-btn cancel'
                        placeholder='Close'
                        onClick={handleModal}
                    />

                    <ButtonContainer
                        className='dialog__container-btn'
                        placeholder='Print'
                        onClick={handlePrint}
                        disabled={!listDirecitons}
                    />
                </div>

            </>
        );
    }
    return (
        <>
            <Link className={'hyperlink'} onClick={handleModal}>Get directions</Link>
            <DialogContainer
                header={headerTemplate}
                content={HandleContent}
                footer={footerTemplate}
                visible={showModal} />
        </>
    );
}

export default GetDirections;