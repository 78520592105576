import React from "react";
import DentalOptions from "../Dental/DentalOptions";
import VisualDentalDisclaimer from "../Disclaimer/VisualDentalDisclaimer";
import CoverageYearField from "../Fields/CoverageYearField";
import StateField from "../Fields/StateField";
import VisionOptions from "../Visual/VisionOptions";

const VisionDentalFiltersForm = ({selectedProviderType}) => {
    return (
        <>
            <VisualDentalDisclaimer/>
            <CoverageYearField />
            <StateField />

            {
                selectedProviderType === 3 && (
                    <DentalOptions />
                )
            }

            {
                selectedProviderType === 4 && (
                    <VisionOptions />
                )
            }
        </>
    )
}

export default VisionDentalFiltersForm;