import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { isMobile } from '../../common/widgets';
import { IDialogType } from '../models/DialogType';
import './DialogContainer.scss';

const DialogContainer = ({ content, header, footer, visible }: IDialogType) => {

    return (
        <Dialog
            fullScreen={isMobile()}
            className='ProviderSearch__dialog'
            open={visible}
        >
            <DialogTitle>
                <>
                    {header()}
                </>
            </DialogTitle>
            <DialogContent>
                <>
                    {content()}
                    {footer()}
                </>
            </DialogContent>
        </Dialog>
    )
}

export default DialogContainer;