import { RootReducer } from '../reducers';

export const providerSelector = {
    selectedProviderType: (state: RootReducer) => state.provider.selectedProviderType,
    specialtiesData: (state: RootReducer) => state.provider.specialtiesData,
    facilitiesData: (state: RootReducer) => state.provider.facilitiesData,
    selectedSpecialization: (state: RootReducer) => state.provider.guidedSearch.specialization,
    coverageYearData: (state: RootReducer) => state.provider.coverageYearData,
    planData: (state: RootReducer) => state.provider.planData,
    selectedFacilityType: (state: RootReducer) => state.provider.guidedSearch.facilityType,
    selectedLocation: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.location
        } else {
            return state.provider.quickSearch.location
        }
    },
    servicesTypeData: (state: RootReducer) => state.provider.serviceTypeData,
    selectedCoverageYear: (state: RootReducer) => state.provider.guidedSearch.selectedCoverageYear,
    selectedDoctorType: (state: RootReducer) => state.provider.guidedSearch.selectedDoctorType,
    selectedPlan: (state: RootReducer) => state.provider.guidedSearch.selectedPlan,
    medicalGroupsData: (state: RootReducer) => state.provider.medicalGroupsData,
    selectedMedicalGroup: (state: RootReducer) => state.provider.guidedSearch.selectedMedicalGroup,
    selectedVisitType: (state:RootReducer) => state.provider.selectedVisitType,
    selectedLanguage: (state: RootReducer) => state.provider.selectedLanguage,
    selectedGender: (state: RootReducer) => state.provider.selectedGender,
    selectedAcceptNewPatiences: (state: RootReducer) => state.provider.selectedAcceptNewPatients,
    selectedProviderAffiliation: (state: RootReducer) => state.provider.selectedProviderAffiliation,
    selectedServiceType: (state: RootReducer) => state.provider.selectedServiceType,
    searchTerm: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.searchTerm
        } else {
            return state.provider.quickSearch.searchTerm
        }
    },
    showResults: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.showResults
        } else {
            return state.provider.quickSearch.showResults
        }
    },
    providers: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.providers
        } else {
            return state.provider.quickSearch.providers
        }
    },
    indexPage : (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.pageIndex
        } else {
            return state.provider.quickSearch.pageIndex
        }
    },
    sortby: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.sortby
        } else {
            return state.provider.quickSearch.sortby
        }
    },
    withinmiles: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.withinmiles
        } else {
            return state.provider.quickSearch.withinmiles
        }
    },
    totalRecords: (state: RootReducer) => {
        if (state.provider.tabIndex === 0) {
            return state.provider.guidedSearch.totalRecords
        } else {
            return state.provider.quickSearch.totalRecords
        }
    },
    languagesData: (state: RootReducer) => state.provider.languagesData,
    isLoading: (state: RootReducer) => state.provider.loading,
    isLoadingData: (state: RootReducer) => state.provider.loadingData,
    isCommingBackHome: (state: RootReducer) => state.provider.backToHome,
    selectedUSAState: (state: RootReducer) => state.provider.selectedUSAState,
    selectedNPI: (state: RootReducer) => state.provider.selectedNPI,
    hasError: (state: RootReducer) => state.provider.error,
    hasMessage: (state: RootReducer) => state.provider.successMessage,
    affiliationData: (state: RootReducer) => state.provider.medicalGroupAffiliation,
    providerCategories: (state: RootReducer) => state.provider.providerCategory,
    emailSearch: (state: RootReducer) => state.provider.emailSearch,
    selectedQuery: (state: RootReducer) => ({
        providerCategory: state.provider.providerCategory ?? [],
        location: state.provider.guidedSearch.location,
        searchTerm: state.provider.guidedSearch.searchTerm,
        npi: state.provider.selectedNPI ?? '',
        sortby: state.provider.guidedSearch.sortby,
        medicalGroup: state.provider.guidedSearch.selectedMedicalGroup ?? '',
        plan: state.provider.guidedSearch.selectedPlan ?? '',
        withinmiles: state.provider.guidedSearch.withinmiles,
        visitType: state.provider.selectedVisitType ?? '',
        gender: state.provider.selectedGender ?? '',
        specialization: state.provider.guidedSearch.specialization ?? '',
        language: state.provider.selectedLanguage ?? '',
        acceptNewPatients: state.provider.selectedAcceptNewPatients ?? '',
        serviceType: state.provider.selectedServiceType ?? '',
        coverageYear: state.provider.guidedSearch.selectedCoverageYear ?? null,
        pageIndex: state.provider.guidedSearch.pageIndex,
        providerAffiliation: state.provider.selectedProviderAffiliation ?? '',
    }),
    selectedQuickQuery: (state: RootReducer) => ({
        location: state.provider.quickSearch.location,
        searchTerm: state.provider.quickSearch.searchTerm,
        sortby: state.provider.quickSearch.sortby,
        withinmiles: state.provider.quickSearch.withinmiles,
        pageIndex: state.provider.quickSearch.pageIndex,
    }),
    sortingData: (state: RootReducer) => state.provider.sortingData,
    updatingRadio: (state: RootReducer) => state.provider.updatingRadio,
    tabIndex: (state: RootReducer) => state.provider.tabIndex,
    witResponse: (state: RootReducer) => state.provider.witResponse,
    isAllAccordionsExpanded: (state: RootReducer) => state.provider.isAllAccordionsExpanded,
    isSecondaryAccordionsExpanded: (state: RootReducer) => state.provider.isSecondaryAccordionsExpanded,
    locationAccordionsExpanded: (state: RootReducer) => state.provider.locationAccordionsExpanded,
}