import { datadogRum } from '@datadog/browser-rum';

export const initDataDog = (env: string) => {
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APP_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: 'us3.datadoghq.com',
        service: 'ava-provider-search',
        env: env,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
    });
}