import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateSearch, refreshPlanNetwork, getPlanData } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, mapRequiredSelector } from "../../common/filters";
import { ButtonContainer, DropdownContainer, DialogContainer, Spinner } from "../../components";
import PlanDialog from "../Dialog/PlanDialog";
import useTrackEvent from "../../common/utils/useTrackEvent";
import { AuditEventTypes, Menus, ProviderType, SubMenus } from "../../common/utils/TrackEventTypes";

const PlanField = ({setShowPPODisclaimer}:any) => {
    const [showModal, setshowModal] = useState(false);
    const [disabledForm, setDisabledForm] = useState(true);
    const dispatch = useDispatch();

    const providerData = providerSelector;
    const providerType = useSelector(providerData.selectedProviderType);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const planData = useSelector(providerData.planData);
    const selectedType = useSelector(providerData[mapRequiredSelector[providerType]]);
    const selectedPlan = useSelector(providerData.selectedPlan);
    const selectedMedicalGroup = useSelector(providerData.selectedMedicalGroup);
    const isLoading = useSelector(providerData.isLoadingData);
    const [planValue, setPlanValue] = useState(null);
    const commingBack = useSelector(providerData.isCommingBackHome);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchWhySelectPlanClicked,
        { menu: Menus.GuidedSearch, subMenu: SubMenus[providerType as ProviderType] }
    );

    useEffect(() => {
        if(selectedLocation && selectedType && selectedCoverageYear) {
            setDisabledForm(false);
        } else {
            setDisabledForm(true);
        }
    }, [selectedLocation, selectedType, selectedCoverageYear]);

    useEffect(() => {
        if(selectedLocation && selectedCoverageYear && !commingBack) {
            dispatch(getPlanData());
        }
    }, [selectedLocation, selectedCoverageYear, commingBack]);

    useEffect(() => {
        if (selectedPlan && planData){
            const tempVal = planData?.find((element: any) => element['value'] === selectedPlan);
            setPlanValue(tempVal);
        } else {
            setPlanValue(null);
            setShowPPODisclaimer(false);
        }
    }, [selectedPlan, planData]);

    const handleModal = () => {
        if (!showModal) {
            trackEvent();
        }
        setshowModal(!showModal);
    }

    const headerTemplate = () => {
        return (
            <div className='dialog__header'>
                <div className='dialog__header-title'>
                    Why Should I Select a Plan?
                </div>
                <button className='dialog__header-btn' onClick={handleModal}>
                    <CancelIcon sx={{ fontSize: 38.54 }} />
                </button>
            </div>
        )
    }

    const footerTemplate = () => {
        return (
            <div>
                <ButtonContainer
                    className='dialog__container-btn'
                    placeholder='Close'
                    onClick={handleModal}
                />
            </div>
        );
    }

    const handleChange = (_event: any, newValue: String | any, reason: any) => {
        if (reason === 'clear'){
            dispatch(updateSearch({
                guidedSearch: {selectedPlan: null}
            }));
            setPlanValue(null);
            setShowPPODisclaimer(false);
            return
        }

        //due the changes to MUI, the newValue is now an object for objec display dropdown
        const tempData = newValue['value'];
        // Disclaimer is only visible for PPO plans
        const showDisclaimer = tempData?.includes('PPO');
        setShowPPODisclaimer(showDisclaimer);

        if(selectedMedicalGroup) {
            dispatch(updateSearch({
                guidedSearch: {selectedPlan: tempData}
            }));
        } else {
            dispatch(refreshPlanNetwork({
                root: { medicalGroupsData: null},
                guidedSearch: {
                    selectedPlan: tempData
                }
            }))
        }
        setPlanValue(newValue);
    };

    return (
        <>
            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <div className="fieldgroup__placeholder-title">
                        Plan (optional)

                        <ButtonContainer
                            className='fieldgroup__help'
                            placeholder='Why should I select a plan?'
                            onClick={handleModal}
                        />
                    </div>
                </div>

                <div className={isLoading && !planValue ? 'fieldgroup__field_loading' : `fieldgroup__field`}>
                    <DropdownContainer
                        id={'PlanDropdown'}
                        className="fieldgroup__select"
                        dataSource={planData}
                        placeholder={placeholdersTxt.plan}
                        onChange={handleChange}
                        enabled={!planData || disabledForm}
                        value={disabledForm ? null : planValue}
                        showClearButton={false}
                        dataIsAnObject={true}
                    />
                    {
                        isLoading && !planValue && (
                            <Spinner size={40}/>
                        )
                    }
                </div>
            </div>

            <DialogContainer
                content={PlanDialog}
                header={headerTemplate}
                footer={footerTemplate}
                visible={showModal}
            />
        </>
    )
}

export default PlanField;