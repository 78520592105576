import React from 'react';
import { CircularProgress } from '@mui/material';
import './Spinner.scss';

const Spinner = (props: any) => {
    return (
        <CircularProgress size={props.size ? props.size : 100} color='inherit'/>
    )
}

export default Spinner;