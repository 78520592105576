export enum  AuditEventTypes {
    ProviderSearchWhySelectPlanClicked = 'ProviderSearchWhySelectPlanClicked',
    ProviderSearchWhySelectMedicalGroupClicked = 'ProviderSearchWhySelectMedicalGroupClicked',
    ProviderSearchWhatIsThisClicked = 'ProviderSearchWhatIsThisClicked',
    ProviderSearchToolTipClicked = 'ProviderSearchToolTipClicked',
    ProviderSearchSearchProviderButtonClicked = 'ProviderSearchSearchProviderButtonClicked',
    ProviderSearchQuickSearchButtonClicked = 'ProviderSearchQuickSearchButtonClicked',
    ProviderSearchDentalSearchButtonClicked = 'ProviderSearchDentalSearchButtonClicked',
    ProviderSearchVisionSearchButtonClicked = 'ProviderSearchVisionSearchButtonClicked',
    ProviderSearchEmailButtonClicked = 'ProviderSearchEmailButtonClicked',
    ProviderSearchPrintButtonClicked = 'ProviderSearchPrintButtonClicked',
    ProviderSearchReportIncorrectInformationClicked = 'ProviderSearchReportIncorrectInformationClicked',
    ProviderSearchProviderSearchHelpClicked = 'ProviderSearchProviderSearchHelpClicked',
    ProviderSearchDownloadProviderDirectoryClicked = 'ProviderSearchDownloadProviderDirectoryClicked',
}


export enum Menus {
    GuidedSearch = 'Guided Search',
    QuickSearch = 'Quick Search'
}

export const Tabs = {
    1: 'Guided Search',
    2: 'Quick Search'
}

export const SubMenus = {
    1: 'Doctors',
    2: 'Facilities',
    3: 'Dental',
    4: 'Vision',
    5: 'Other Specialty Services',
}

export type ProviderType = keyof typeof SubMenus;

export type TabIndexType = keyof typeof Tabs;

export interface EventDetails {
    menu: string;
    subMenu?: string;
}
