import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Error from 'components/Error';
import Help from './components/Help';
import Home from './components/Home';
import ProviderDetailsContainer from './components/ProviderDetails';
import ScrollToTop from './components/ScrollTop';
import './App.scss';
import { initDataDog } from './datadog';
import { clearQueryParams, validateQueryParams } from './util/queryParamsUtils';

const App = () => {
    const [env, setEnv] = useState<string>('');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (env) {
            initDataDog(env);
        } else {
            fetch('api/environment')
                .then(response => response.json())
                .then(response => {
                    setEnv(response?.env)
                });
        }
    }, [env]);

    useEffect(() => {
        const { isModified, searchParams } = clearQueryParams(new URLSearchParams(location.search));
        if (isModified){
            navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
            }, { replace: true });
        }
    }, []);

    if (!validateQueryParams(new URLSearchParams(location.search))){
        return <Navigate to="/error" replace />;
    }

    return (
        <>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/:npi" element={<ProviderDetailsContainer />} />
                    <Route path="/providerId/:providerId" element={<ProviderDetailsContainer />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/error" element={<Error />} />
                </Routes>
            </ScrollToTop>
        </>
    );
}

export default App;