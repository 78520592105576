import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { expandAllLocationAccordions } from "redux/actions";
import LocationAccordion from "./LocationAccordion";
import { IProviderItemType } from "../models/ProviderDataType";
import { locationsDisclaimer } from "../../common/filters/utils";
import Disclosure from "../Common/Disclosure/Disclosure";
import { AcceptingAVAHmoDisclaimer } from "../avaHmo";
import "./LocationAccordion.scss";
import ButtonContainer from "../Button/ButtonContainer";

const Locations = ({ provider }: IProviderItemType) => {
    const [isAllAccordionsExpanded, setAllExpended] = useState(false);
    const dispatch = useDispatch();

    const handleModal = () => {
        dispatch(expandAllLocationAccordions(!isAllAccordionsExpanded));
        setAllExpended((currState: boolean) => !currState);
    }

    const disclaimer = <div>
        <p>{locationsDisclaimer.message}</p>
        <AcceptingAVAHmoDisclaimer locations={provider?.locations} />
    </div>;

    return (
        <div className="location__div">
            <div className="location__container">
                <div className="location_title">
                    Locations &amp; Medical Groups
                </div>
                <div className="location__hmo__disclaimer">
                    <Disclosure content={disclaimer} customCssClass="disclosure-content location-disclosure no-background" />
                </div>
                {provider?.locations && provider?.locations.length > 1 && (
                    <div className="location_expand__btn__div">
                        <ButtonContainer
                            className='location_expand__btn'
                            placeholder={isAllAccordionsExpanded ? "Collapse all" : "Expand all"}
                            onClick={handleModal}
                        />
                    </div>
                )}
                <div className="location_details__div">
                    <LocationAccordion provider={provider} />
                </div>
            </div>
        </div>
    )
}

export default Locations;