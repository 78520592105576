import React, { FC } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import { ProviderIcon } from "../Icons/SvgIcons";
import { ILocationsPreviewType } from "../models/LocationsPreviewType";
import { ILocationType } from "../models/LocationType";
import { getFullAddress, getPhoneNumberWithFormat } from "../utils";
import "./LocationsPreview.scss";

const LocationsPreview: FC<ILocationsPreviewType> = ({
    provider,
    onCloseHandler,
}) => {
    return (
        <div className="locationsPreview__container">
            <div className="header__container">
                <div className="icon__container locationIcon__container">
                    {ProviderIcon['locations']}
                    <span className="location__length">{provider?.locations.length}</span>
                </div>
                <div className="title__container">
                    <span>Locations</span>
                </div>
                <div className="icon__container">
                    <button className="buttonIcon" onClick={onCloseHandler}>
                        <CancelIcon sx={{ fontSize: 38.54 }} />
                    </button>
                </div>
            </div>
            <div className="locationsList__container">
                <ul className="locationsList">
                    {provider?.locations?.map(
                        (location: ILocationType, index: number) => {
                            return (
                                <li key={`provider${provider?.npi}location${index}`}>
                                    <div className="locationDetail__container">
                                        <div className="locationName__container">
                                            <span>{location.practiceName}</span>
                                        </div>
                                        <div className="locationAddress__container">
                                            <span className="locationAddress">
                                                {getFullAddress(location)}
                                            </span>
                                        </div>
                                        <div className="locationNumber__container">
                                            <span className="locationNumber">
                                                {getPhoneNumberWithFormat(location.phoneNumber)}
                                            </span>
                                        </div>
                                    </div>
                                    <Divider role="presentation" />
                                </li>
                            );
                        }
                    )}
                </ul>
            </div>
        </div>
    );
};

export default LocationsPreview;
