import React from "react"

export const IconHouse = (className: any) => {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 496 496" enableBackground="new 0 0 496 496;" >
            <g>
                <g>
                    <g>
                        <path d="M496,48V0H0v48h16v160H0v48h16v224H0v16h496v-16h-16V256h16v-48h-16V48H496z M416,448h-16v16h16v16H288v-16h96v-16h-96
					v-16h128V448z M416,384h-96v16h96v16H288v-16h16v-16h-16v-16h128V384z M416,352H288v-16h128V352z M464,480h-32V336h16v-16H256v16
					h16v144H32V256h432V480z M480,240H16v-16h464V240z M32,208V48h432v160H32z M16,32V16h464v16H16z"/>
                        <path d="M96,184h128V72H96V184z M168,88h40v32h-40V88z M168,136h40v32h-40V136z M112,88h40v32h-40V88z M112,136h40v32h-40V136z"
                        />
                        <path d="M272,184h128V72H272V184z M344,88h40v32h-40V88z M344,136h40v32h-40V136z M288,88h40v32h-40V88z M288,136h40v32h-40V136z
					"/>
                        <path d="M224,304H96v112h128V304z M152,400h-40v-32h40V400z M152,352h-40v-32h40V352z M208,400h-40v-32h40V400z M208,352h-40v-32
					h40V352z"/>
                    </g>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
