import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { genderMockedData, placeholdersTxt } from "../../common/filters";
import { DropdownContainer,TooltipContainer,ProviderIcon } from "../../components";

const GenderField = () => {
    const genderData = genderMockedData;
    const selector = providerSelector
    const selectedGender = useSelector(selector.selectedGender);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: string) => {
        const gender = newValue;
        dispatch(updateFilters({selectedGender:gender}))
    }

    return (
        <>
            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <div className="fieldgroup__placeholder-title">
                        Gender
                    </div>
                    <TooltipContainer
                        position="BottomCenter" content={""} disableHover={true}
                    >
                        <div className="fieldgroup__placeholder-icon">
                            { ProviderIcon['tooltip']}
                        </div>
                    </TooltipContainer>
                </div>

                <div className="fieldgroup__field">
                    <DropdownContainer
                        id={'GenderDropdown'}
                        className="fieldgroup__select"
                        dataSource={genderData}
                        placeholder={placeholdersTxt.default}
                        onChange={handleChange}
                        value={selectedGender}
                        dataIsAnObject={false}
                        enabled={false}
                    />
                </div>
            </div>
        </>
    )
}

export default GenderField;