import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem';

const PagerComponentPSMUI = (props: any) => {

    const sxStyle = {
        "& .MuiPaginationItem-root.Mui-selected": { color: "#fff", background: "#1b54c2", borderRadius: "50%" },
        "& .MuiButtonBase-root": {fontSize: "24px", fontFamily: 'Arial, sans-serif', fontWeight: 'normal'},
        "& .MuiButtonBase-root svg": { fontSize: "28px"}
    }

    return (
        <Pagination {...props}
            showFirstButton={true}
            showLastButton={true}
            sx={sxStyle}
            renderItem={(item) => (
                <PaginationItem
                    slots={{ first: SkipPreviousIcon, previous: ArrowLeftIcon, next: ArrowRightIcon, last: SkipNextIcon }}
                    {...item}
                />
            )}
        />
    )
}

export default PagerComponentPSMUI;