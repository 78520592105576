import React from "react";
import GenderField from "../Fields/GenderField";
import LanguageField from "../Fields/LanguageField";
import NewPatientField from "../Fields/NewPatientField";
import VisitTypeField from "../Fields/VisitTypeFieldField";

const FiltersDoctor = () => {
    return (
        <>
            <div className="filters-container__row">
                <VisitTypeField />
                <LanguageField />
                <GenderField />
                <NewPatientField />
            </div>
        </>
    );
};

export default FiltersDoctor;
