import React, { FC, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from "react-redux";
import { clearMessages } from "redux/actions";
import { IAlertType } from '../models/AlertType';
import './AlertContainer.scss';

const AlertContainer: FC<IAlertType> = ({ severity, message, title }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        dispatch(clearMessages())
    }

    const getIcon = (severity: string | undefined) => {
        switch (severity) {
            case 'error':
                return <RemoveCircleIcon fontSize="inherit" sx={{ color: "#F05022" }} />;
            case 'success':
                return <CheckCircleIcon fontSize="inherit" sx={{ color: "#78BD42" }} />
            default:
                return null;
        }
    }

    const getClass = (severity: string | undefined) => {
        switch (severity) {
            case 'error':
                return 'alert-messages-error';
            case 'success':
                return 'alert-messages-success';
            default:
                return 'alert-messages';
        }
    }

    return (
        <Collapse in={open}>
            <Alert
                icon={getIcon(severity)}
                severity={severity}
                className={getClass(severity)}
                variant='filled'
                action={
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        tabIndex={0}
                    >
                        <CloseIcon fontSize="inherit" sx={{ color: "#000000" }} />
                    </IconButton>
                }
            >
                <AlertTitle>{title ?? severity}</AlertTitle>
                {message}
            </Alert>
        </Collapse>
    );
}

export default AlertContainer;