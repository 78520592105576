import React, { FC, useEffect, useState } from "react";
import "./AccordionComponent.scss";
import {
    faAngleUp,
    faAngleDown,
    faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IAccordionType } from "../models/AccordionType";

const AccordionComponent: FC<IAccordionType> = ({
    id,
    header,
    content,
    className,
    expanded,
    isSecondary,
    isLocation,
    locationIndex,
}) => {
    const [isExpanded, setIsExpanded] = useState(expanded)

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);

    const toggleHandler = () => {
        setIsExpanded((current) => !current);
    }

    return (
        <div id={"accordion-header-" + id} className={className ? className : "accordion_background"}>
            <div className={isSecondary ? "accordion_background_secondary" : "accordion_background_main"} onClick={toggleHandler}>
                <div className={isSecondary ? "accordion_header-secondary font-demi" : "accordion_header font-demi"}>
                    {isLocation ? (
                        <div className="location-index__container">
                            <div className="header-title__container">
                                <span>Location</span>
                            </div>
                            <div className="icon-title__container">
                                <FontAwesomeIcon title="" icon={faLocationPin} />
                                <span className="location-index">{locationIndex}</span>
                            </div>
                        </div>
                    ) : (
                        header
                    )}
                </div>
                <div className="accordion_icon">
                    {isExpanded ? (
                        <FontAwesomeIcon title="" icon={faAngleDown} />
                    ) : (
                        <FontAwesomeIcon title="" icon={faAngleUp} />
                    )}
                </div>
            </div>
            {isExpanded && <div className={`accordion_content ${isSecondary && "accordion_content_secondary"}`}>{content}</div>}
        </div>
    );
};

export default AccordionComponent;
