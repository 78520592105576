import React, { FC, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { calculateMilesAway } from "../avaHmo";
import Doctor from "../Doctor/Doctor";
import LocationsPreview from "../LocationsPreview/LocationsPreview";
import MedicalGroupsPreview from "../MedicalGroupsPreview/MedicalGroupsPreview";
import { IProviderItemType } from "../models/ProviderDataType";
import { ProviderContentType } from "../utils";
import { ProviderIcon } from "../Icons/SvgIcons";
import  { Icon } from '../../components'
import "./ProviderCard.scss";

const ProviderCard: FC<IProviderItemType> = ({ provider, showBadge }) => {
    const [currentContent, setCurrentContent] = useState<ProviderContentType>(
        ProviderContentType.Provider
    );
    const milesAway = calculateMilesAway(provider?.locations);

    const toggleContent = (content: ProviderContentType) => {
        setCurrentContent(content);
    };

    const getProviderCard = () => {
        return (
            <>
                <Doctor
                    key={`doctor${provider?.npi}`}
                    provider={provider}
                    showBadge={showBadge}
                />
                <div className="icons__innerContainer">
                    <button
                        className="icon__container"
                        onClick={() => toggleContent(ProviderContentType.Locations)}
                    >
                        <div className="icon__text__container">
                            <Icon icon="hospital" />
                            <p className="text__next_icon">
                                <strong>
                                    {Array.isArray(provider?.locations)
                                        ? provider?.locations.length
                                        : 0}
                                </strong>
                            </p>
                        </div>
                        <p>Locations</p>
                    </button>
                    <button
                        className="icon__container"
                        onClick={() => toggleContent(ProviderContentType.Groups)}
                    >
                        <div className="icon__text__container">
                            {ProviderIcon['medicalGroup']}
                            <p className="text__next_icon">
                                <strong>
                                    {provider?.numberOfMedicalGroupsThisProviderHas}
                                </strong>
                            </p>
                        </div>
                        <p>Groups</p>
                    </button>
                    {milesAway > 0 && (
                        <span className="icon__container">
                            <div className="icon__text__container">
                                <MdLocationPin />
                                <p className="text__next_icon">
                                    <strong> {milesAway.toFixed(2)}</strong>
                                </p>
                            </div>
                            <p> Miles away</p>
                        </span>
                    )}
                </div>
            </>
        );
    };

    const getContent = () => {
        switch (currentContent) {
            case ProviderContentType.Groups:
                return (
                    <MedicalGroupsPreview
                        key={`medicalGroup${provider?.npi}`}
                        provider={provider}
                        onCloseHandler={() => toggleContent(ProviderContentType.Provider)}
                    />
                );
            case ProviderContentType.Locations:
                return (
                    <LocationsPreview
                        key={`locations${provider?.npi}`}
                        provider={provider}
                        onCloseHandler={() => toggleContent(ProviderContentType.Provider)}
                    />
                );

            default:
                return getProviderCard();
        }
    };

    return <div className="providerCard__container">{getContent()}</div>;
};

export default ProviderCard;
