import React, { useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sortBy, changeRadio, resetIndexPage } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { sortValues, sortValuesInternal, sortValuesDoctor, miles, SelectContainer, SendEmail, ButtonContainer, Spinner } from "../../components";
import './ResultActions.scss';
import { needResetIndexPage } from "../utils";
import useTrackEvent from '../../common/utils/useTrackEvent';
import { AuditEventTypes, Menus, ProviderType, SubMenus } from '../../common/utils/TrackEventTypes';
import { mapRequiredSelector } from '../../common/filters';

const ResultActions = (props: any) => {
    const refComponent = useRef(null);
    const dispatch = useDispatch();
    const selector = providerSelector;
    const selectedLocation = useSelector(selector.selectedLocation);
    const selectedTotalRecords = useSelector(selector.totalRecords);
    const selectedProviders = useSelector(selector.providers);
    const selectedSortBy = useSelector(selector.sortby);
    const withinMiles = useSelector(selector.withinmiles);
    const indexPage = useSelector(selector.indexPage);
    const updatingRadio = useSelector(selector.updatingRadio);
    const providerType = useSelector(selector.selectedProviderType);
    const tabIndex = useSelector(selector.tabIndex);
    const selectedType = useSelector(selector[mapRequiredSelector[providerType]]);
    const selectedCoverageYear = useSelector(selector.selectedCoverageYear);
    const selectedPlan = useSelector(selector.selectedPlan);
    const selectedMedicalGroup = useSelector(selector.selectedMedicalGroup);
    const searchTerm = useSelector(selector.searchTerm);

    const eventDetails = {
        menu: tabIndex === 0 ? Menus.GuidedSearch : Menus.QuickSearch,
        subMenu: tabIndex === 0 ? SubMenus[providerType as ProviderType] : undefined,
        searchParameters: tabIndex === 0 ? {
            selectedType,
            coverageYear: selectedCoverageYear,
            location: selectedLocation,
            selectedPlan,
            selectedMedicalGroup,
            searchTerm,
        } : {
            searchTerm,
            location: selectedLocation,
        }
    };

    const trackPrintEvent = useTrackEvent(AuditEventTypes.ProviderSearchPrintButtonClicked, eventDetails);

    const doIndexReset = () => {
        if (needResetIndexPage(indexPage)) {
            let searchType;
            if (tabIndex === 0) {
                searchType = 'guidedSearch';
            } else {
                searchType = 'quickSearch';
            }
            dispatch(resetIndexPage(searchType));
        }
    }

    const handleSort = (newValue: string) => {
        const type = tabIndex === 0 ? 'guidedSearch' : 'quickSearch';
        dispatch(sortBy(newValue, type))
        doIndexReset();
    }

    const handleRadioChange = (newValue: number) => {
        const type = tabIndex === 0 ? 'guidedSearch' : 'quickSearch';
        dispatch(changeRadio(newValue, type))
        doIndexReset();
    }

    const isDoctor = (providerType: Number) => {
        return providerType === 1;
    }

    const printDocument = () => window.print();

    return (
        <div className='resultActions__OuterContainer' ref={refComponent}>
            <div className='resultActions__InnerContainer'>
                <div className='resultActions__section'>
                    <span style={{padding: '0 10px'}}>
                        {
                            selectedLocation && (
                                <span className='mobile__hide'><strong>{selectedLocation}</strong> |  </span>
                            )
                        }
                        {updatingRadio ? (
                            <span className="resultActions__spinner">
                                <Spinner size={20} />
                            </span>
                        ) : <b>{selectedTotalRecords}&nbsp;</b>}
                        <b>results</b> within
                    </span>
                    <div className="fieldgroup__field">
                        <SelectContainer
                            className='result__filters'
                            dataSource={miles}
                            value={withinMiles}
                            onChange={handleRadioChange}
                            dataIsAnObject={true}
                        />
                    </div>
                </div>

                <div className='resultActions__section'>
                    <span className='icon__container'>
                        <SendEmail
                            iconName="email"
                            selectedProvider={selectedProviders}
                            type="searchRequest"
                            eventDetails={eventDetails}
                        />
                    </span>
                    <span className='icon__container'>
                        <ButtonContainer
                            className='send-email__container'
                            placeholder='Print'
                            onClick={() => {
                                printDocument();
                                trackPrintEvent();
                            }}
                            icon="print"
                        />
                    </span>
                    <span className='mobile__hide'>Sort By:</span>
                    <SelectContainer
                        className='result__filters'
                        dataSource={props.isProviderInternal ? sortValuesInternal : isDoctor(providerType) ? sortValuesDoctor : sortValues}
                        onChange={handleSort}
                        value={selectedSortBy}
                        dataIsAnObject={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default ResultActions;