import React, { useState } from 'react';
import CancelIcon from "@mui/icons-material/Cancel";
import ProviderTypeBullet from './ProviderTypeBullet';
import ProviderTypesField from './ProviderTypesField';
import ProviderTypesInfo from './ProviderTypesInfo';
import { isMobile } from '../../common/widgets';
import { ButtonContainer, DialogContainer, FiltersContainer } from "../../components";
import { IProviderType } from '../models/ProviderType';
import { providerTypes } from '../utils';
import useTrackEvent from "../../common/utils/useTrackEvent";
import { AuditEventTypes, Menus, ProviderType, SubMenus } from "../../common/utils/TrackEventTypes";
import './ProviderTypes.scss';

const ProviderTypesContainer = ({ selectedProviderType }: { selectedProviderType: number; }) => {
    const [showModal, setshowModal] = useState(false);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchWhatIsThisClicked,
        { menu: Menus.GuidedSearch, subMenu: SubMenus[selectedProviderType as ProviderType] }
    );

    const handleModal = () => {
        if (!showModal) {
            trackEvent();
        }
        setshowModal(!showModal)
    }

    const headerTemplate = () => {
        return (
            <div className='dialog__header'>
                <div className='dialog__header-title'>
                    Provider Type
                </div>
                <button className='dialog__header-btn' onClick={handleModal}>
                    <CancelIcon sx={{ fontSize: 38.54 }} />
                </button>
            </div>

        )
    }

    const footerTemplate = () => {
        return (
            <div>
                <ButtonContainer
                    className='dialog__container-btn'
                    placeholder='Close'
                    onClick={handleModal}
                />
            </div>
        );
    }

    return (
        <>
            <div className='providertypes'>
                <p className='providertypes__title'>
                    <span>{isMobile() ? 'Provider Type*' : 'Select Provider Type'}</span>
                    <ButtonContainer
                        className='providertypes__help'
                        placeholder='What is this?'
                        onClick={handleModal}
                    />
                </p>

                <div className='providertypes__bullets'>
                    {
                        providerTypes.map((type: IProviderType) => (
                            <ProviderTypeBullet key={type.value} {...type} selectedProviderType={selectedProviderType} />
                        ))
                    }
                </div>

                <div className='providertypes__bullets-mobile'>
                    <ProviderTypesField selectedProviderType={selectedProviderType} />
                </div>

                <FiltersContainer />
            </div>

            <DialogContainer
                content={ProviderTypesInfo}
                header={headerTemplate}
                footer={footerTemplate}
                visible={showModal}
            />
        </>
    )
}

export default ProviderTypesContainer;