import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IDropdownType } from '../models/DropdownType';

const DropdownContainer: FC<IDropdownType> = ({ id, className, dataSource, placeholder, onChange, value, enabled, showClearButton, dataIsAnObject }) => {
    const [inputValue, setInputValue] = React.useState("");
    const getOptionText = (option: any) => {return dataIsAnObject ? String(option.text) : String(option);}

    const handleOnChange = (event: any , value: any, reason: any) => {
        if (onChange) {
            onChange(event, value, reason);
        }
        setInputValue(getOptionText(value));
    };

    const isOptionEqualToValue = (option: any, value: any) => {
        if (dataIsAnObject) {
            return option.text == value.text;
        } else {
            return option == value;
        }
    };

    const getOptionStyle = (value: any) => {
        return {
            backgroundColor: isOptionEqualToValue(inputValue, getOptionText(value)) ? "#e4e4e4 !important" : "#fff",
            lineHeight: "30px",
        };
    };

    return (
        <Autocomplete
            id={id ? id : ''}
            className={`${className} ${enabled ? 'disabled' : ''}`}
            options={dataSource ?? []}
            renderOption={(props, option) => {
                return (
                    <span {...props} style={getOptionStyle(option)}>
                        {getOptionText(option)}
                    </span>
                );
            }}
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
            disableClearable={showClearButton}
            getOptionLabel={getOptionText}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleOnChange}
            value={value}
            disabled={enabled}
            noOptionsText="No results found for the search criteria you selected. Please adjust your filters."
            popupIcon={<KeyboardArrowDownIcon/>}
        />
    )
}

export default DropdownContainer