import React from "react";
import LinkContainer from "../Link/LinkContainer";
import './PlanDisclaimer.scss';

const PlanDisclaimer = () => {
    return (
        <div className="ppodisclaimer__container">
            <div className="ppodisclaimer__title">
                <div className="ppodisclaimer__title-icon">
                    i
                </div>
                <div className="ppodisclaimer__title-text">
                    PPO Search
                </div>
            </div>

            <div className="ppodisclaimer__content">
                <p>
                    By selecting a PPO plan in this filter, you will see the list of your in-network providers below.  Please note that you can still visit providers not listed here. These visits will be applied to your out-of-network allowance.
                </p>
                <p>
                    See your <LinkContainer className={"hyperlink"} placeholder={"Evidence of Coverage (EOC)"} urlAddress={"EOCUrl"} spaSettings={true} newTab={true}></LinkContainer> for further details, or you can call Alignment Health Plan Member Services at 1-866-634-2247 (TTY 711) for any questions.<br />8:00 a.m. to 8:00 p.m., 7 days a week (except Thanksgiving and Christmas) from October 1 through March 31, and Monday to Friday (except holidays) from April 1 through September 30.
                </p>
            </div>
        </div>
    )
}

export default PlanDisclaimer;