import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowUpCircle } from "react-icons/bs";
import {  ArrowCircleUp } from '../../icons/arrowCircleUp'
import {Merge} from '../../icons/call-merge';
import { Email } from '../../icons/envelope';
import { GoldBadge } from '../../icons/GoldBadge';
import { IconHospital } from '../../icons/hospital';
import { IconHouse } from '../../icons/house';
import { IconPdf } from '../../icons/pdf';
import { IconDistancePin } from '../../icons/pin';
import { IconPlus } from '../../icons/plus';
import { Print } from '../../icons/print';
import { Asterisk } from '../../icons/Asterisk';
import { SilverBagde } from '../../icons/SilverBadge';
import { TakeExit } from '../../icons/takeExit';
import { TurnLeft } from '../../icons/TurnLeft';
import { TurnRight } from '../../icons/turnRight';
import { GoStraigth } from '../../icons/up-arrow';
import { Underline } from '../../icons/Underline';

const Icon = (props: { icon: any; className?: string }) => {
    const className = `svg-inline--fa fa-w-16 ${props.className}`;

    switch (props.icon) {
        case 'hospital':
            return <IconHospital className={className} />
        case 'house':
            return <IconHouse className={className} />
        case 'distance':
            return <IconDistancePin className={className} />
        case 'plus':
            return <IconPlus className={className} />
        case 'right':
            return <TurnRight className={className} />
        case 'left':
            return <TurnLeft className={className} />
        case 'go':
            return <GoStraigth className={className} />
        case 'exit':
            return <TakeExit className={className} />
        case 'merge':
            return <Merge className={className} />
        case 'email':
            return <Email className={className} />
        case 'print':
            return <Print className={className} />
        case 'goTop':
            return <BsArrowUpCircle className={className}/>
        case 'gold':
            return <GoldBadge />
        case 'silver':
            return <SilverBagde />
        case 'scrollTop':
            return <ArrowCircleUp className={className} />
        case 'pdf':
            return <IconPdf className={className} />
        case 'asterisk':
            return <Asterisk className={className} />
        case 'underline':
            return <Underline className={className} />
        default:
            return null;
    }
}

export default Icon;

Icon.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};