export const CapitalizeFirst = (inputString: string) => {
    let str = inputString.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const PhoneNumber = (phone: string) => {
    return " ("+phone.slice(0, 3)+") "+ phone.slice(3, 6)+ "-"+ phone.slice(6);
}

export const getSpecialities = (specialities: any[]) => {
    let orderSpecialities = specialities.sort();
    return orderSpecialities.join(', ');
}

export const isMobile = () => {
    return window.innerWidth < 760;
}