import React, { FC, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { PhoneNumber } from "../../common/widgets";
import GetDirections from "../GetDirections/GetDirections";
import { IAccordionMedicalGroupsParticipated, ILocationType, IMedicalGroupsParticipated } from "../models/LocationType";
import { ProviderIcon } from "../Icons/SvgIcons";
import { calculateMilesAway } from "../avaHmo";
import AccordionComponent from "../Accordion/AccordionComponent";
import ButtonContainer from "../Button/ButtonContainer";
import "./LocationCard.scss";
import MedicalGroup from "../MedicalGroup/MedicalGroup";

const LocationCard: FC<ILocationType> = (location) => {
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
    const [medicalGroups, setMedicalGroups] = useState<IAccordionMedicalGroupsParticipated[]>([]);
    const milesAway = calculateMilesAway([location]);
    const services = location.hospitalServices.map(
        (service: string, index: number) => {
            return <li key={`service${index}`}>{service}</li>;
        }
    );

    const expandAccordionHandler = () => {
        const groups = location.medicalGroupsParticipated?.map((medicalGroups: IMedicalGroupsParticipated) => {
            return {
                ...medicalGroups,
                expanded: !isAccordionExpanded
            }
        });
        setMedicalGroups(groups);
        setIsAccordionExpanded((currState: boolean) => !currState);
    }

    const getMedicalGroups = () => {
        if (medicalGroups.length === 0) {
            const groups = location.medicalGroupsParticipated?.map((medicalGroups: IMedicalGroupsParticipated) => {
                return {
                    ...medicalGroups,
                    expanded: true
                }
            });
            return groups
        }

        return medicalGroups;
    }

    return (
        <>
            <div className="accordion-content__container">
                <div className="location-details__container">
                    <div className="content">
                        {location.isMobile || location.isVirtual ? (
                            <div className="details__container">
                                <div>
                                    <h4>{location.practiceName}</h4>
                                    <strong> {location.homeVisitProvDisclaimer} </strong>
                                </div>
                                <div className="icons__container">
                                    <div className="icon__container">
                                        <div className="icon__text__container">
                                            {ProviderIcon["medicalGroup"]}
                                            <p className="text__next_icon">
                                                <strong>
                                                    {location.medicalGroupsParticipated.length}
                                                </strong>
                                            </p>
                                        </div>
                                        <p className="icon_description">Groups</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="details__container">
                                    <div className="directions__container">
                                        <h4>{location.practiceName}</h4>
                                        <span>
                                            {`${location.addressLine1} ${location.addressLine2} ${location.city}, ${location.state} ${location.zipCode}`}
                                        </span>
                                        <span>{PhoneNumber(location.phoneNumber)}</span>
                                        <GetDirections {...location} />
                                    </div>
                                    <div className="icons__container">
                                        <div className="icon__container">
                                            <div className="icon__text__container">
                                                {ProviderIcon["medicalGroup"]}
                                                <p className="text__next_icon">
                                                    <strong>
                                                        {location.medicalGroupsParticipated.length}
                                                    </strong>
                                                </p>
                                            </div>
                                            <p className="icon_description">Groups</p>
                                        </div>
                                        {milesAway != 0 &&
                                            <div className="icon__container">
                                                <div className="icon__text__container">
                                                    <MdLocationPin />
                                                    <p className="text__next_icon">
                                                        <strong>{milesAway.toFixed(2)}</strong>
                                                    </p>
                                                </div>
                                                <p className="icon_description">Miles away</p>
                                            </div>
                                        }
                                    </div>
                                </div>

                                {location.hospitalServices && location.hospitalServices.length > 0 && (
                                    <div className="service_accordion__container">
                                        <div className="service__container">
                                            <AccordionComponent
                                                id={1}
                                                className="location-service__container"
                                                header={<span>services</span>}
                                                isSecondary={true}
                                                expanded={false}
                                                content={
                                                    <ul className="service-list__container">
                                                        {services}
                                                    </ul>
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="medical-group_expand__wrapper">
                            <ButtonContainer
                                className='medical-group_expand__btn'
                                placeholder={`${isAccordionExpanded ? "Collapse all" : "Expand all"} medical groups`}
                                onClick={expandAccordionHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {getMedicalGroups().map(
                (medicalGroups: IAccordionMedicalGroupsParticipated, index: number) => (
                    <AccordionComponent
                        id={index}
                        key={index}
                        className="md-accordion__container"
                        header={<span>{medicalGroups.medicalGroupDescription}</span>}
                        content={
                            <MedicalGroup
                                acceptingNewPatients={medicalGroups.acceptingNewPatients}
                                isPCP={location?.isPCP}
                                isSpecialist={location?.isSpecialist}
                                locationIndex={location?.locationIndex}
                                specialties={medicalGroups.specialties[0]}
                                participateNetworks={medicalGroups.participatedNetworks}
                                providerId={medicalGroups.providerID}
                                disclaimer={medicalGroups.disclaimer}
                                panelDescription={medicalGroups.panelDescription}
                            />
                        }
                        isSecondary={true}
                        expanded={medicalGroups.expanded}
                        locationIndex={location?.locationIndex}
                    ></AccordionComponent>
                )
            )}
        </>
    );
};

export default LocationCard;
