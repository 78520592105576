import React from 'react';
import { configureStore  } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createEpicMiddleware } from 'redux-observable';
import { ajax } from "rxjs/ajax";
import App from './App';
import { reducers, epics } from './redux/reducers';

const epicMiddleware = createEpicMiddleware({
    dependencies: {
        postINFO: ajax.post,
        getJSON: ajax.getJSON,
    }
});

const store = configureStore({
    reducer:reducers,
    middleware: [epicMiddleware]
});

epicMiddleware.run(epics);

const rootElement = document.getElementById('root');

ReactDOM.render(
    <>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </>,
    rootElement
);