import React, { FC } from "react";
import {
    faCircleCheck,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IMedicalGroup } from "../models/MedicalGroupType";
import AccordionComponent from "../Accordion/AccordionComponent";
import Disclosure from "../Common/Disclosure/Disclosure";
import "./MedicalGroup.scss";

const MedicalGroup: FC<IMedicalGroup> = ({
    acceptingNewPatients,
    isPCP,
    isSpecialist,
    specialties,
    participateNetworks,
    providerId,
    locationIndex,
    disclaimer,
    panelDescription,
}) => {
    const getPlans = (participatedNetwork: any) => {
        const AVA_HMO_NAME = "AVA (HMO)";
        const plans = participatedNetwork.pbpInfo.map(
            (pbp: { pbpName: any; pbpid: any; planYear: any }, index: number) => {
                const pbpDescription =
                    pbp.pbpName === AVA_HMO_NAME
                        ? `${pbp.pbpName} ${pbp.pbpid}* `
                        : `${pbp.pbpName} ${pbp.pbpid} `;
                return <p key={pbp.pbpid + index}>{pbpDescription}</p>;
            }
        );

        return plans;
    }

    return (
        <div>
            <div className="medical_info__container">
                <div className="wrapper-left">
                    <div className="provider__container">
                        <span>Provider ID: </span>
                        <span>{providerId}</span>
                    </div>
                    <div className="pcp__container">
                        {
                            isPCP && (
                                <div>
                                    <span>PCP: </span>
                                    <span>{specialties}</span>
                                </div>
                            )
                        }
                        {
                            isSpecialist && (
                                <div>
                                    <span>Specialist: </span>
                                    <span>{specialties}</span>
                                </div>
                            )
                        }
                        {
                            (!isPCP && !isSpecialist) ? (
                                <div>
                                    <span>Service type: </span>
                                    <span>{specialties}</span>
                                </div>
                            ) : ''
                        }
                    </div>
                    <div>
                        {(isPCP || isSpecialist) && (
                            <div className="accepting-new-patients__container font-book">
                                <FontAwesomeIcon
                                    title=""
                                    icon={
                                        acceptingNewPatients ? faCircleCheck : faExclamationCircle
                                    }
                                    color={acceptingNewPatients ? "#76BC21" : "#f9a127"}
                                />
                                <span>{panelDescription}</span>
                            </div>
                        )}
                    </div>
                    {disclaimer &&
                        <div className="diclaimer-container">
                            <Disclosure content={disclaimer} />
                        </div>
                    }
                </div>
                <div className="plans_accordion__container">
                    <div className="plans__container">
                        {participateNetworks.map((item: any, index: any) => (
                            <AccordionComponent
                                key={index}
                                id={item.planYear}
                                className="md-plans__container"
                                header={<span>Participates in {item.planYear}</span>}
                                isSecondary={true}
                                expanded={locationIndex == 0}
                                locationIndex={locationIndex}
                                content={<div className="pbp-list__container">{getPlans(item)}</div>}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MedicalGroup;
