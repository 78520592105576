export const tooltipsTxt = {
    provider: 'Select Primary Care Provider (PCP) or the doctor specialty (e.g. Cardiology, Oncology, Psychiatry, etc.)',
    facilities: 'Select Hospital, Urgent Care Center, and Skilled Nursing Facility (SNF)',
    services: 'Select Radiology, Dialysis Center, Durable Medical Equipment, Home Health Agency, Sleep Medicine, Physical Therapy and more',
    coverage: 'Select current year if you are already a member (your plan is effective). Select next year, if your plan is not effective until next year.',
    providerName: 'You can narrow search results by entering provider name, practice name, services, NPI, or specialty',
    searchTermForFacilities: 'You can narrow search results by entering facility name, services, NPI, or specialty',
    state: 'Select a state',
    newPatient: 'A provider may be accepting new patients for some medical groups and not others. View the provider details to confirm availability by medical group.​',
    medicalGroupAffiliation: 'Select provider’s medical group to view providers affiliated with that medical group. This will exclude partnered medical group providers.',
    emptyMilesAway: 'To see distance information, please provide a location during search.',
}

export const placeholdersTxt = {
    default: 'Select one',
    provider: 'Primary Care Provider (PCP), Cardiologist, Oncologist, Psychiatrist, Urologist, and more',
    facilities: 'Hospital, Urgent Care Center, and Skilled Nursing Facility (SNF)',
    services: 'Radiology, Dialysis Center, Durable Medical Equipment, Home Health Agency, ...',
    coverage: 'Select a year',
    location: 'Enter zip code, city, county or address',
    plan: 'Select your plan',
    medicalGroup: 'Select a medical group',
    providerNameDoctor: 'Enter a provider name, practice name, services, NPI, or specialty',
    providerNameFacility: ' Enter a facility name, services, NPI, or specialty',
    state: 'Select a state',
    medicalGroupAffiliation: 'Select a medical group provider',
    emailName: 'Enter your name',
    emailAddress: 'Enter an email address',
    emailMessage: 'Enter an additional message',
    providerType: 'Doctors, Facilities, Vision, Dental, Other Specialty Services',
}

export const mobilePlaceholdersTxt = {
    provider: 'Primary Care Provider (PCP), Cardiologist, Oncologist, Psychiatrist, Urologist, and more',
    facilities: 'Hospital, Urgent Care Center, and Skilled Nursing Facility (SNF)',
    services: 'Radiology, Dialysis Center, Durable Medical Equipment, Home Health Agency, ...',
    coverage: 'Select a year',
    location: 'Zip, city, county or address',
    plan: 'Select your plan',
    medicalGroup: 'Select a medical group',
    providerNameDoctor: 'First and/or last name',
    providerNameFacility: 'Enter a facility name',
    state: 'Select a state',
    visit: 'Select a visit type',
    language: 'Select a language',
    medicalGroupAffiliation: 'Select a medical group provider',
    emailName: 'Enter your name',
    emailAddress: 'Enter an email address',
    emailMessage: 'Enter an additional message',
    providerType: 'Doctors, Facilities, Vision, Dental, Other Specialty Services'
}

export const mapRequiredSelector = {
    1: 'selectedDoctorType',
    2: 'selectedFacilityType',
    5: 'selectedSpecialization'
}

export const newPatientMockedData = ['Yes', 'No'];

export const genderMockedData = ['Female', 'Male'];

export const visitTypeData = [
    { text: "All", value: "All" },
    { text: "In-Office", value: "In-Office" },
    { text: "Home Visits", value: "Home Visits Only" },
    { text: "Virtual", value: "Virtual" }
];

export const dropdownFields = { text: 'text', value: 'value' }

export const dataCategoTypeForFacilities = new Map<String, Number>([["Hospital", 3], ["Urgent Care Center", 4], ["Skilled Nursing Facility (SNF)", 5]]);

export const dentalLinks = {
    'Arizona': 'https://dentaquest.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=DENTAQUEST_I&brandCode=DENTAQUEST/results/allRemote=false&alphaPrefix=&isPromotionSearch=true&key=&location=Arizona%252C%2520US&maxLatitude=&maxLongitude=&minLatitude=&minLongitude=&page=1&patientAge=&providerType=&query=&radius=25&searchType=default&searchCategory=GENERAL&sort=DEFAULT&waitForOop=false&doWebAlert=true&productCode=AZ-ALIGN',
    'California': 'https://client.libertydentalplan.com/ahp',
    'Nevada': 'https://dentaquest.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=DENTAQUEST_I&brandCode=DENTAQUEST/results/allRemote=false&alphaPrefix=&isPromotionSearch=true&key=&location=Nevada%252C%2520US&maxLatitude=&maxLongitude=&minLatitude=&minLongitude=&page=1&patientAge=&providerType=&query=&radius=25&searchType=default&searchCategory=GENERAL&sort=DEFAULT&waitForOop=false&doWebAlert=true&productCode=NV-ALIGNCARE',
    'North Carolina': 'https://dentaquest.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=DENTAQUEST_I&brandCode=DENTAQUEST/results/allRemote=false&alphaPrefix=&isPromotionSearch=true&key=&location=North%2520Carolina%252C%2520US&maxLatitude=&maxLongitude=&minLatitude=&minLongitude=&page=1&patientAge=&providerType=&query=&radius=25&searchType=default&searchCategory=GENERAL&sort=DEFAULT&waitForOop=false&doWebAlert=true&productCode=NC-ALIGNCARE',
}

export const dentalData = {
    2024: {
        'Arizona': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage varies by plan and is offered through DentaQuest and/or Flex Allowance.", "Members with dental coverage through the Flex Allowance may visit a dental provider of their choice that accepts VISA.  There is no network or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "To view the dental network, click the link below to be routed to the DentaQuest website."],
            url: "https://www.dentaquest.com/",
            providerName: 'DentaQuest'
        },
        'California': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage and provider network(s) vary by plan and is administered through Liberty Dental and/or Flex Allowance.", "Members with dental coverage through the Flex Allowance may visit a dental provider of their choice that accepts VISA.  There is no network or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "To view the dental network(s) corresponding to your plan, click the link below to be routed to the Liberty Dental website."],
            url: "https://client.libertydentalplan.com/ahp",
            providerName: "Liberty Dental",
        },
        'Florida': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage varies by plan and is offered through Liberty Dental.", "To view the dental network corresponding to your plan, click the link below to be routed to the Liberty Dental website."],
            url: "https://client.libertydentalplan.com/ahp",
            providerName: "Liberty Dental"
        },
        'Texas': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage varies by plan and is offered through Liberty Dental.", "To view the dental network corresponding to your plan, click the link below to be routed to the Liberty Dental website."],
            url: "https://client.libertydentalplan.com/ahp",
            providerName: 'Liberty Dental'
        },
        'North Carolina': {
            text: ["Coverage varies by plan and is offered through Careington and/or Flex Allowance.", "For plans with coverage through Careington, members can view the Careington PPO dental network through the Careington PPO website.  Select plans have additional coverage through the Flex Allowance.", "To view the dental PPO network, click the link below to be routed to the Careington website."],
            enhancedText: ["For plans with coverage through the Flex Allowance, members may visit a dental provider of their choice that accepts VISA.  There is no network restriction or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "Members also have the option to pair the Flex Allowance benefit with the Dental Discount Plan to obtain significant savings on dental services received.", "To view the dental discount network, click the link below to be routed to the Careington website."],
            url: "https://www.careington.com/co/careppo/",
            providerName: "Careington PPO",
            enhancedUrl: "http://alignmenthealth.solutionssimplified.com",
            enhancedProviderName: "Careington Discount"
        },
        'Nevada': {
            text: ["Coverage varies by plan and is offered through Careington and/or Flex Allowance.", "For plans with coverage through Careington, members can view the Careington PPO dental network through the Careington PPO website.  Select plans have additional coverage through the Flex Allowance.", "To view the dental PPO network, click the link below to be routed to the Careington website."],
            enhancedText: ["For plans with coverage through the Flex Allowance, members may visit a dental provider of their choice that accepts VISA.  There is no network restriction or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "Members also have the option to pair the Flex Allowance benefit with the Dental Discount Plan to obtain significant savings on dental services received.", "To view the dental discount network, click the link below to be routed to the Careington website."],
            url: "https://www.careington.com/co/careppo/",
            providerName: "Careington PPO",
            enhancedUrl: "http://alignmenthealth.solutionssimplified.com",
            enhancedProviderName: "Careington Discount"
        },
    },
    2025: {
        'Arizona': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage varies by plan and is offered through DentaQuest and/or Flex Allowance.", "Members with dental coverage through the Flex Allowance may visit a dental provider of their choice that accepts VISA.  There is no network or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "To view the dental network, click the link below to be routed to the DentaQuest website."],
            url: "https://www.dentaquest.com/",
            providerName: 'DentaQuest'
        },
        'California': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage and provider network(s) vary by plan and is administered through Liberty Dental and/or Flex Allowance.", "Members with dental coverage through the Flex Allowance may visit a dental provider of their choice that accepts VISA.  There is no network or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "To view the dental network(s) corresponding to your plan, click the link below to be routed to the Liberty Dental website."],
            url: "https://client.libertydentalplan.com/ahp",
            providerName: "Liberty Dental",
        },
        'Texas': {
            text: ["Alignment Health prioritizes affordable access to oral health maintenance services.  Members may also purchase additional comprehensive dental coverage depending on their individual oral health needs.", "Coverage varies by plan and is offered through Liberty Dental.", "To view the dental network corresponding to your plan, click the link below to be routed to the Liberty Dental website."],
            url: "https://client.libertydentalplan.com/ahp",
            providerName: 'Liberty Dental'
        },
        'North Carolina': {
            text: ["Coverage varies by plan and is offered through Careington and/or Flex Allowance.", "For plans with coverage through Careington, members can view the Careington PPO dental network through the Careington PPO website.  Select plans have additional coverage through the Flex Allowance.", "To view the dental PPO network, click the link below to be routed to the Careington website."],
            enhancedText: ["For plans with coverage through the Flex Allowance, members may visit a dental provider of their choice that accepts VISA.  There is no network restriction or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "Members also have the option to pair the Flex Allowance benefit with the Dental Discount Plan to obtain significant savings on dental services received.", "To view the dental discount network, click the link below to be routed to the Careington website."],
            url: "https://www.careington.com/co/careppo/",
            providerName: "Careington PPO",
            enhancedUrl: "http://alignmenthealth.solutionssimplified.com",
            enhancedProviderName: "Careington Discount"
        },
        'Nevada': {
            text: ["Coverage varies by plan and is offered through Careington and/or Flex Allowance.", "For plans with coverage through Careington, members can view the Careington PPO dental network through the Careington PPO website.  Select plans have additional coverage through the Flex Allowance.", "To view the dental PPO network, click the link below to be routed to the Careington website."],
            enhancedText: ["For plans with coverage through the Flex Allowance, members may visit a dental provider of their choice that accepts VISA.  There is no network restriction or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, available 24/7.", "Members also have the option to pair the Flex Allowance benefit with the Dental Discount Plan to obtain significant savings on dental services received.", "To view the dental discount network, click the link below to be routed to the Careington website."],
            url: "https://www.careington.com/co/careppo/",
            providerName: "Careington PPO",
            enhancedUrl: "http://alignmenthealth.solutionssimplified.com",
            enhancedProviderName: "Careington Discount"
        },
    }
}

export const visualLink = 'https://www.vsp.com/advantageretail';

export const enhancedDentalLinks = 'https://www1.deltadentalins.com/ahp';

export const disclosureText = {
    quickSearch: 'Provider Participation varies by plan and/or medical group. You can click one of the search results to see detailed provider information including contracted medical groups and accepted plans at each location for in-network coverage.'
}

export const locationsDisclaimer = {
    message: 'Provider participation varies according to the combination of your health plan and your medical group network. Please expand to view the list of medical groups and plans by location.'
};