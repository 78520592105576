import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, tooltipsTxt, dentalData } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer, Icon } from "../../components";
import { Menus, ProviderType, SubMenus } from "../../common/utils/TrackEventTypes";

const StateField = () => {
    const selector = providerSelector;
    const selectedCorevageYear = useSelector(selector.selectedCoverageYear)
    const selectedUSAState = useSelector(selector.selectedUSAState);
    const providerType = useSelector(selector.selectedProviderType);
    const [stateData, setStateData] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedCorevageYear) {
            const temp = Object.keys(dentalData[selectedCorevageYear as unknown as keyof Object]) as any;
            setStateData(temp);
        }
    }, [selectedCorevageYear])

    const handleChange = (_event: any, newValue: any, reason: any) => {
        if (reason === 'clear') {
            dispatch(updateSearch({ root: { selectedUSAState: null } }))
            return;
        }
        dispatch(updateSearch({ root: { selectedUSAState: newValue } }))

    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    <span>State</span>
                    <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                </div>
                <TooltipContainer
                    position="BottomCenter"
                    content={tooltipsTxt.state}
                    eventDetails={{
                        menu: Menus.GuidedSearch,
                        subMenu: SubMenus[providerType as ProviderType],
                        toolTipFor: 'State',
                        content: tooltipsTxt.state,
                    }}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>

            </div>

            <div className="fieldgroup__field">
                <DropdownContainer
                    id={'StateDropdown'}
                    className="fieldgroup__select"
                    dataSource={stateData}
                    placeholder={placeholdersTxt.state}
                    onChange={handleChange}
                    value={selectedUSAState}
                    dataIsAnObject={false}
                    enabled={!selectedCorevageYear}
                />
            </div>
        </div>
    )
}

export default StateField;