import React, { FC } from 'react';
import Link from '@mui/material/Link';
import { get } from 'lodash';
import { ILinkContainerType } from '../models/LinkContainerType';
import useTrackEvent from '../../common/utils/useTrackEvent';

const LinkContainer: FC<ILinkContainerType> = ({ className, placeholder, urlAddress, spaSettings, underline = true, newTab, eventName }) => {
    const URL = get(window, "spaSettings." + urlAddress);
    const trackEvent = useTrackEvent(eventName, {});

    const handleClick = () => {
        if (eventName) {
            trackEvent();
        }
    }

    return (
        <Link
            className={className}
            href={spaSettings ? URL : urlAddress}
            underline={underline ? "always" : "none"}
            target={newTab ? "_blank" : ""}
            rel={"noreferrer"}
            onClick={handleClick}
        >
            {placeholder}
        </Link>
    )
}

export default LinkContainer;