import React from "react"

export const TurnRight = (className: any) => {
    return (
        <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 32.303 32.303" enableBackground="new 0 0 32.303 32.303;" >
            <g>
                <path d="M0.009,30.124c0,0-0.135,0.514,0.645,0.518c0.783-0.004,4.467-0.004,5.027-0.004c0.555,0.004,0.545-0.43,0.545-0.43
			c0-0.736,0-14.467,0-18.475c6.301,0,14.43,0,16.117-0.004c0.805,0.004,1.326,0,1.326,0s0,1.922,0,3.135
			c0,1.209,0.91,0.295,0.91,0.295s6.791-5.244,7.51-5.959c0.514-0.52-0.061-1.01-0.061-1.01l-7.279-6.148c0,0-0.863-0.91-0.861,0.078
			c0,0.996,0,3.393,0,3.393s-0.586,0-1.48,0.002c-1.752-0.002-9.904-0.002-16.182,0c0-0.002,0-0.002,0-0.006H0.478h-0.08
			C0.255,5.52,0.036,5.618,0.009,6.067c0,1.705,0,3.467,0,5.152C0.009,15.395,0.009,29.339,0.009,30.124z"/>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}
