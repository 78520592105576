import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { IProviderSearch } from './models/ProviderSearch';
import { postEmailEpic, providerSearch, searchProviderEpic, searchSpecialtiesEpic, getLanguagesEpic, getProviderServicesEpic, postIncorrectInformationEpic, getPlanYearSwitchEpic, getWitResponseEpic} from './ProviderSearchReducer';

export interface RootReducer {
    provider: IProviderSearch
}

export const reducers = combineReducers<RootReducer>({ provider: providerSearch });
export const epics = combineEpics<any>(searchProviderEpic, searchSpecialtiesEpic, postEmailEpic, getLanguagesEpic, getProviderServicesEpic, postIncorrectInformationEpic, getPlanYearSwitchEpic, getWitResponseEpic);