import React from 'react';

export const SilverBagde = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="64" height="78.97" viewBox="12 5 40.004 61.968">
            <defs>
                <filter id="Union_3" x="0" y="0" width="64" height="63.662" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.102" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Subtraction_3" x="7.668" y="43.488" width="48.67" height="35.482" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodOpacity="0.102" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_24083" data-name="Group 24083" transform="translate(-1842.184 -1919.678)">
                <g transform="matrix(1, 0, 0, 1, 1842.18, 1919.68)" filter="url(#Union_3)">
                    <path id="Union_3-2" data-name="Union 3" d="M18.258,43.839a6.764,6.764,0,0,1-5.079-.317A6.586,6.586,0,0,1,9.667,39.9,6.689,6.689,0,0,1,5.2,37.508a6.48,6.48,0,0,1-1.625-4.74A6.563,6.563,0,0,1,.55,28.74a6.442,6.442,0,0,1,.567-4.969,6.451,6.451,0,0,1-1-4.9A6.519,6.519,0,0,1,2.765,14.6a6.446,6.446,0,0,1,1.2-4.862A6.674,6.674,0,0,1,8.2,6.966a6.561,6.561,0,0,1,3.177-3.907,6.418,6.418,0,0,1,6.13-.313l.022,0A6.354,6.354,0,0,1,23,0a6.726,6.726,0,0,1,4.743,1.823,6.765,6.765,0,0,1,5.078.315,6.587,6.587,0,0,1,3.514,3.623A6.7,6.7,0,0,1,40.8,8.154a6.478,6.478,0,0,1,1.625,4.741,6.56,6.56,0,0,1,3.022,4.028,6.435,6.435,0,0,1-.567,4.97,6.446,6.446,0,0,1,1,4.9,6.52,6.52,0,0,1-2.651,4.271,6.446,6.446,0,0,1-1.2,4.861,6.667,6.667,0,0,1-4.24,2.768A6.565,6.565,0,0,1,34.621,42.6a6.426,6.426,0,0,1-6.144.306A6.352,6.352,0,0,1,23,45.662,6.724,6.724,0,0,1,18.258,43.839Z" transform="translate(9 6)" fill="#3c617d" />
                </g>
                <circle id="Ellipse_56" data-name="Ellipse 56" cx="16.915" cy="16.915" r="16.915" transform="translate(1857.756 1932.202)" fill="#f5f5f8" />
                <g transform="matrix(1, 0, 0, 1, 1842.18, 1919.68)" filter="url(#Subtraction_3)">
                    <path id="Subtraction_3-2" data-name="Subtraction 3" d="M30.67,17.482h-.314L23,14.888V3.478c.594-.127,1.178-.27,1.735-.427A13.3,13.3,0,0,0,30.67,0V17.48h0Zm-30.353,0H0V0A13.286,13.286,0,0,0,5.937,3.05c.554.156,1.136.3,1.729.426V14.89Zm11.73-4.136h0V4.108c1.074.088,2.182.133,3.291.133s2.208-.044,3.28-.132v9.234l-3.283-1.158-3.288,1.159Z" transform="translate(16.67 49.49)" fill="#3c617d" />
                </g>
                <text id="_4" data-name="4" transform="translate(1864.185 1959.509)" fill="#3c617d" fontSize="32" fontFamily="Raleway, sans-serif" fontWeight="700"><tspan x="1" y="-4">4</tspan></text>
            </g>
        </svg>
    )
}