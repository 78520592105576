import React from "react";
import Link from '@mui/material/Link';
import { useSelector } from "react-redux";
import { providerSelector } from "redux/selectors/providerSelectors";
import { visualLink } from "../../common/filters";
import { AuditEventTypes, Menus } from "../../common/utils/TrackEventTypes";
import useTrackEvent from "../../common/utils/useTrackEvent";

const VisionOptions = () => {
    const selector = providerSelector;
    const selectedUSAState = useSelector(selector.selectedUSAState);
    const selectedCoverageYear = useSelector(selector.selectedCoverageYear);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchVisionSearchButtonClicked,
        {
            menu: Menus.GuidedSearch,
            searchParameters: {
                coverageYear: selectedCoverageYear,
                state: selectedUSAState,
            }
        }
    );

    const contentText = () => {
        return <p className="filters-container__text">Alignment Health Plan members enjoy vision benefits such as eye exams, glasses, and contact lenses. Coverage varies by plan and is offered through VSP and/or through the Flex Allowance.<br /><br />For plans with coverage through VSP, members can view the VSP vision network through the VSP website. Select plans have additional coverage through the Flex Allowance.<br /><br />For plans with coverage through the Flex Allowance, members can visit a vision provider of their choice that accepts VISA. There is no network restriction or impact on covered standalone benefits. For additional information regarding the Flex Allowance benefit, members can call the ACCESS On-Demand Concierge Team at 1-833-242-2223, TTY 711, available 24/7.<br /><br />To view the vision network, click the link below to be routed to the VSP website.</p>
    }

    const displayLink = () => {
        return true;
    }

    return (
        <>
            {
                selectedUSAState && selectedCoverageYear && (contentText())
            }
            {
                displayLink() && selectedUSAState && (
                    <Link
                        className={`fieldgroup__submit ${!selectedUSAState ? 'disabled' : ''}`}
                        href={visualLink}
                        underline="none"
                        target={"_blank"}
                        onClick={trackEvent}
                    >
                        Find a Vision Provider from VSP Network
                    </Link>
                )
            }</>
    )
}

export default VisionOptions;