import React, { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postEmail, clearMessages } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import SendEmailWithIcon from './SendEmailWithIcon';
import { ButtonContainer, DialogContainer } from "../index";
import { ISendEmail } from "../models/SendEmail";
import { sanitizeParameter } from "../utils";
import './SendEmail.scss';
import useTrackEvent from "../../common/utils/useTrackEvent";
import { AuditEventTypes } from "../../common/utils/TrackEventTypes";

const SendEmail: FC<ISendEmail> = ({ iconName, selectedProvider, eventDetails }) => {
    const selector = providerSelector;
    const tabIndex = useSelector(selector.tabIndex);
    const selectedQuery = useSelector(selector.selectedQuery);
    const selectedQuickQuery = useSelector(selector.selectedQuickQuery);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchEmailButtonClicked,
        eventDetails
    );

    let query;
    if (tabIndex === 0) {
        query = selectedQuery;
    } else {
        query = selectedQuickQuery;
    }

    const dispatch = useDispatch();
    const [showModal, setshowModal] = useState(false);
    const [emailFields, setEmailFields] = useState({
        emailName: null,
        emailAddress: null,
        emailMessage: null
    });

    const handleModal = () => {
        if (showModal) {
            setEmailFields({
                emailName: null,
                emailAddress: null,
                emailMessage: null
            });

            dispatch(clearMessages());
        } else {
            trackEvent();
        }
        setshowModal(!showModal);
    }

    const handleChange = (event: { target: { name: any, value: any } }) => {
        setEmailFields({
            ...emailFields,
            [event.target.name]: event.target.value
        })
    }

    const handleContent = () => {
        return <SendEmailWithIcon handleChange={handleChange} />
    }

    const handleSubmit = () => {
        if (selectedProvider) {
            let urlParams = `${window.location.protocol}//${window.location.host}/`;
            const urlAddress = 'api/email';

            if (Array.isArray(selectedProvider)) {
                const specialization = sanitizeParameter(query.specialization || '');

                if (tabIndex === 0) {
                    urlParams = urlParams.concat(`?providerCategory=${query.providerCategory.join(',')}&location=${query.location}&searchTerm=${query.searchTerm}&selectedNPI=${query.npi}&sortby=${query.sortby}&selectedMedicalGroup=${query.medicalGroup}&selectedPlan=${query.plan}&withinmiles=${query.withinmiles}&selectedVisitType=${query.visitType}&selectedGender=${query.gender}&specialization=${specialization}&selectedLanguage=${query.language}&selectedAcceptNewPatients=${query.acceptNewPatients}&selectedServiceType=${query.serviceType}&selectedCoverageYear=${query.coverageYear}&selectedProviderAffiliation=${query.providerAffiliation}&pageIndex=${query.pageIndex}&tabIndex=${tabIndex}`);
                } else {
                    urlParams = urlParams.concat(`?providerCategory=&location=${query.location}&searchTerm=${query.searchTerm}&selectedNPI=&sortby=${query.sortby}&selectedMedicalGroup=&selectedPlan=&withinmiles=${query.withinmiles}&selectedVisitType=&selectedGender=&specialization=&selectedLanguage=&selectedAcceptNewPatients=&selectedServiceType=&selectedCoverageYear=&selectedProviderAffiliation=&pageIndex=${query.pageIndex}&tabIndex=${tabIndex}`);
                }
            } else {
                urlParams = urlParams.concat(selectedProvider.npi);
            }

            const emailDetails = {
                query: {
                    ...query,
                    coverageYear: query.coverageYear?.toString(),
                },
                name: emailFields.emailName,
                email: emailFields.emailAddress,
                message: emailFields.emailMessage,
                url: urlParams,
            };

            const emailPayload = {
                emailDetails,
                urlAddress
            }

            dispatch(postEmail(emailPayload));
            handleModal();
        }
    }

    const isValidEmail = (email: string | null) => {
        if (!email) {
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const headerTemplate = () => {
        return (
            <div className='dialog__header'>
                <div className='dialog__header-title'>
                    Send Provider Details
                </div>
                <button
                    className='dialog__header-btn'
                    onClick={handleModal}
                > X </button>
            </div>
        )
    }

    const footerTemplate = () => {
        return (
            <div className="send-email__footer">
                <ButtonContainer
                    className='dialog__container-btn cancel'
                    placeholder='Cancel'
                    onClick={handleModal}
                />

                <ButtonContainer
                    className='dialog__container-btn'
                    placeholder='Send'
                    onClick={handleSubmit}
                    disabled={Boolean(!emailFields.emailName || !isValidEmail(emailFields.emailAddress))}
                />
            </div>
        );
    }

    return (
        <>
            <ButtonContainer
                className='send-email__container'
                placeholder='Email'
                onClick={handleModal}
                icon={iconName}
            />

            {
                showModal && (
                    <DialogContainer
                        content={handleContent}
                        header={headerTemplate}
                        footer={footerTemplate}
                        visible={showModal}
                    />
                )
            }
        </>

    );
};

export default SendEmail;
