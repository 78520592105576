import React, { FC, useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, updateQuickSearch, resetIndexPage } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt, mobilePlaceholdersTxt } from "../../common/filters";
import { LocationFieldType } from "../models/LocationFieldType";
import { needResetIndexPage } from "../utils";
import { isMobile } from "../../common/widgets";
import { Icon } from "../../components";
import { debounce } from "lodash";

const geocoder = new google.maps.Geocoder();
const regex = new RegExp(/\d{5}/);
let zipCode: string | null = null;

const LocationField: FC<LocationFieldType> = ({ isRequired }) => {
    const providerData = providerSelector;
    const selectedLocation: string | null = useSelector(providerData.selectedLocation);
    const indexPage = useSelector(providerData.indexPage);
    const tabIndex = useSelector(providerData.tabIndex);
    const dispatch = useDispatch();
    const locationRef: any = useRef();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!selectedLocation) {
            zipCode = null;
        }
    }, [selectedLocation]);

    const dispatchLocation = debounce((location) => {
        if (tabIndex === 0) {
            dispatch(updateSearch({
                guidedSearch: { location }
            }))
        } else {
            dispatch(updateQuickSearch({
                quickSearch: { location }
            }))
        }
        setShowError(false);
    }, 1000);

    // IMPORTANT: Take in mind when the user clicks on an item, 3 events will run
    // 1. OnBlur event will be trigger when the field lost the focus
    // 2. the code within the geocode callback will run to try to get the place data from google API
    // 3. If user clicks an item on the list, handlePlaceSelected will be trigger inmediately and formatted_addess will be available
    // First 2 events are required for when users edits the field manually without click on an item
    const handlePlaceSelected = (event: any) => {
        const location = event.formatted_address;

        const newZipCode = location.match(regex)?.[0] ?? "";
        // we need to validate the zipcode because the way js works, there is no way of know if this function will be trigger first from the blur or from the click
        if (newZipCode === zipCode) {
            return
        }
        zipCode = newZipCode;
        dispatchLocation(location);

        if (needResetIndexPage(indexPage as number)) {
            let searchType;
            if (tabIndex === 0) {
                searchType = 'guidedSearch';
            } else {
                searchType = 'quickSearch';
            }
            dispatch(resetIndexPage(searchType));
        }
    }

    const handleBlur = (event: any) => {
        // this event will be trigger no matter if user clicks an element on the list or if they manually changed the input value
        const location = event.target.value;

        if (!event.target.value) {
            // if users clear the value, we also clean the redux store
            dispatchLocation("");
            return;
        }

        if (location != selectedLocation) {
            // the event object does not have the same data that the google place, to avoid bugs, we are going to get the place data and compare the zipcode in here too
            // the reason is that if code is different to the one saved on our local variable, user didnt click on the dropdown and they just changed manually the value
            geocoder.geocode({"address":location }, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results?.[0]) {
                        const newZipCode = results[0].formatted_address?.match(regex)?.[0] ?? "";
                        if (newZipCode === zipCode) {
                            return
                        }
                        zipCode = newZipCode;
                        // it is actually possible that this promise gets completed before the click triggers the handlePlaceSelected, so we also need to validate the zip code in here
                        dispatchLocation(results[0].formatted_address);
                    } else {
                        // it is possible that user wants to search a location that does not exist on google api
                        zipCode = null;
                        dispatchLocation(location );
                    }
                }
            });
        }
    }

    const ph = isMobile() ? mobilePlaceholdersTxt : placeholdersTxt;

    useEffect(() => {
        locationRef.current.value = selectedLocation;
        const myInput = document.getElementById(
            "location-text-box"
        ) as HTMLInputElement;
        myInput?.setAttribute("autocomplete", "off");
        myInput?.setAttribute("type", "text");
    }, [selectedLocation]);

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    {isRequired ? (
                        <>
                            <span>Location</span>
                            <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                        </>
                    ) : "Location"}
                </div>
            </div>

            <div className={`fieldgroup__field ${showError ? "error" : ""}`}>
                <Autocomplete
                    ref={locationRef}
                    className="fieldgroup__field-input"
                    id="location-text-box"
                    onPlaceSelected={handlePlaceSelected}
                    placeholder={ph.location}
                    aria-label="Search by zip code or location"
                    options={{
                        types: ['postal_code', 'locality', 'administrative_area_level_2', 'neighborhood'],
                        componentRestrictions: { country: "us" }
                    }}
                    defaultValue={selectedLocation ?? ""}
                    apiKey={'AIzaSyCZUXjld2wi_EmRj5H4Y6idtjx5lkvrjn4'}
                    onBlur={handleBlur}
                />
            </div>

            {showError && <p className="error-message">Please select a value</p>}
        </div>
    );
};

export default LocationField;
