import { useEffect, useState } from 'react';
import { ajax } from 'rxjs/ajax';
import { Subject, takeUntil } from 'rxjs';
import { AuditEventTypes } from "./TrackEventTypes";

type EventType = keyof typeof AuditEventTypes | undefined;

export const ajaxPost = <TResponse = any>(url: string, data: any = null) => {
    return ajax<TResponse>({
        url,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data,
    });
}

export function useUnmountSubject() {
    const [unmountSubject] = useState(new Subject<void>());

    useEffect(() => {
        return () => {
            unmountSubject.next();
            unmountSubject.complete();
        };
    }, []);

    return unmountSubject;
}

export default function useTrackEvent(event: EventType, parameters: any) {
    const unmountSubscriptions$ = useUnmountSubject();

    return () => ajaxPost('/api/trackEvent', { event, parameters: JSON.stringify(parameters) })
        .pipe(takeUntil(unmountSubscriptions$))
        .subscribe();
}