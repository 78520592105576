import React, { FC, useState } from 'react';
import { useDispatch } from "react-redux";
import { selectProviderType } from 'redux/actions';
import { ProviderIcon, Icon } from "../../components";
import { IProviderType } from '../models/ProviderType';
import './ProviderTypes.scss';

const ProviderTypeBullet: FC<IProviderType> = ({ value, text, icon, selectedProviderType }) => {
    const [isButtonOnHover, setIsButtonOnHover] = useState(false);
    const dispatch = useDispatch();

    const handleSelectedProvider = () => {
        dispatch(selectProviderType(value));
    }

    const isActive = selectedProviderType === value;

    return (
        <button className='providertypes__bullet' onClick={handleSelectedProvider} onMouseEnter={() => setIsButtonOnHover(true)} onMouseLeave={() => setIsButtonOnHover(false)}>
            <div className={`providertypes__bullet-icon--${icon} ${isActive ? 'active' : ''}`}>
                {ProviderIcon[icon]}
            </div>
            <div className={`providertypes__bullet-text ${isActive ? 'active' : ''}`}>{text}</div>
            {(isActive || isButtonOnHover) && <Icon icon="underline" className='providertypes__bullet-underline' />}
        </button>
    )
}

export default ProviderTypeBullet;