import React from "react";
import { ceil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { changeIndexPage } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { PagerComponentPSMUI, Spinner } from "../../components";
import ProviderCard from "../Provider/ProviderCard";
import './SearchResults.scss';

const SearchResults = () => {
    const selector = providerSelector;
    const providerData = useSelector(selector.providers);
    const indexPage = useSelector(selector.indexPage);
    const totalResult = useSelector(selector.totalRecords);
    const sortingData = useSelector(selector.sortingData);
    const updatingRadio = useSelector(selector.updatingRadio);
    const tabIndex = useSelector(selector.tabIndex);

    const dispatch = useDispatch();

    const itemsOnAPage = 10;

    const handleChange = (_event: any, page: any) => {
        if (indexPage !== page) {
            let searchType;
            if (tabIndex === 0) {
                searchType = 'guidedSearch';
            } else {
                searchType = 'quickSearch';
            }
            dispatch(changeIndexPage(page, searchType));
        }
    }

    return (
        <div className="providerResults">
            {
                (sortingData || updatingRadio) ? (
                    <div className='spinner-results'>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {
                            providerData?.map((provider: any) => {
                                return <div className={"providerCard__LinkContainer"} key={provider.npi}><ProviderCard provider={provider} key={provider.npi} showBadge={true} /></div>
                            })
                        }
                        {
                            providerData?.length !== 0 && (
                                <div className="providerResult__pager">
                                    <PagerComponentPSMUI count={ceil(totalResult / itemsOnAPage)} onChange={handleChange}
                                        page={indexPage || 1}/>
                                    <div className="pager__information">{indexPage} of {ceil(totalResult / itemsOnAPage)} pages <span className="mobile__hidden">({totalResult} items)</span></div>
                                </div>)
                        }
                        {
                            providerData?.length === 0 && (
                                <p className="provierResult__noResult">Thank you for your interest in Alignment Health Plan. We do not have any providers matching your criteria.</p>
                            )
                        }
                    </>
                )
            }
        </div>
    )
}

export default SearchResults;