import React from "react";
import { get, defaultTo, flattenDeep } from "lodash";
import { AiFillCheckCircle, AiFillExclamationCircle, } from 'react-icons/ai';

// Removed last parenthesis to increase search criteria
const VIRTUAL_PBP_NAME = "AVA (HMO";
const AVA_HMO_PREFIX = "AVA (HMO)";

const hasPbpWithAvaHmo = (locations: any) => {
    const locationpbpNames = defaultTo(locations, []).map((location: any) =>
        flattenDeep(
            get(location, "medicalGroupsParticipated", []).map((group: any) =>
                get(group, "participatedNetworks", []).map((network: any) =>
                    get(network, "pbpInfo", []).map((info: { pbpName: any; }) => info.pbpName)
                )
            )
        )
    );

    // Replaced "===" by "include()" to return true if pbpName contains "AVA(HMO". 
    // We printed all pbpNames to find similar options and found "AVA (HMO)" and "Alignment Health AVA (HMO-POS)". 
    // We don't know if there are more names like these, so we think that by usign this contains check, we are covering all AVA HMO plans.
    return locationpbpNames.some((pbpNames: any[]) => {
        return pbpNames.some((pbpName) => pbpName.includes(VIRTUAL_PBP_NAME));
    });
};

const locationHasVirtualType = (locations: any) => {
    return defaultTo(locations, []).some((location: { isVirtual: any; }) => location.isVirtual);
};

const ParseAcceptingAVAHmo = ({ locations }) => {
    const OFFERING_MSG = "Offering Virtual Visits for AVA (HMO)";
    const NOT_OFFERING_MSG = "Not Offering Virtual Visits for AVA (HMO)";

    if (locationHasVirtualType(locations)) {
        return (
            <>
                <AiFillCheckCircle className='check__icon' />
                {OFFERING_MSG}
            </>
        );
    }
    return (
        <>
            <AiFillExclamationCircle className='exclamation__icon' />
            <span className="PCP_details__ava_hmo_not_offering">
                {NOT_OFFERING_MSG}
            </span>
        </>
    );
};

const AcceptingAVAHmoDisclaimer = ({ locations }) => {
    const HMO_TEXT = "AVA (HMO)";

    if (locationHasVirtualType(locations)) {
        return (
            <span>
                Only Primary Care Providers (PCPs) who offer virtual visits may serve as assigned PCPs for <b>{HMO_TEXT}</b> products.
                Other PCPs who accept <b>{HMO_TEXT}</b> support your Virtual PCP but cannot be chosen as your PCP.
            </span>
        );
    }
    return (
        <></>
    );
}

const asteriskAvaHmoPlanName = (planName: string) => {
    if (planName.includes(AVA_HMO_PREFIX)) {
        return `${planName}*`;
    }
    return planName;
};

const calculateMilesAway = (locations: any) => {
    const filterObj = locations.filter(
        (location: { isVirtual: boolean; latitude: number; distance: number; }) =>
            !location.isVirtual &&
            location.latitude !== 0.0 &&
            location.distance > 0
    );

    let minDistance = 0;
    if (filterObj.length > 0) {
        const distances = filterObj.map((location: { distance: any; }) => location.distance);
        minDistance = Math.min(...distances);
    }

    return minDistance;
}

export {
    ParseAcceptingAVAHmo,
    AcceptingAVAHmoDisclaimer,
    hasPbpWithAvaHmo,
    asteriskAvaHmoPlanName,
    calculateMilesAway,
    locationHasVirtualType
};
