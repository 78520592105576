import React from "react";
import { LinkContainer, InternalLinkContainer, BackToSearchResults } from "../ProviderSearch.CoreLibraries";
import Icon from "../ProviderSearch.CoreLibraries/components/Icons/Icons";
import './Help.scss';
import { AuditEventTypes } from "ProviderSearch.CoreLibraries/common/utils/TrackEventTypes";

const Help = () => {
    return (
        <>
            <BackToSearchResults
                displayIcons={false}
                placeholder={'Back to Provider Search'}
                selectedProvider={null}
            />
            <div className="help-container">
                <div className="help_header__div">
                    <h2>Provider Search Help</h2>
                    <p>
                        This online search provides a list of Alignment Health Plan network providers, and the information is updated
                        regularly.<br />

                        Provider participation varies by plan and/or medical group. For best results, please choose a Plan and/or
                        Medical Group in the search fields and any applicable filters.<br /><br />
                        Refer to your &nbsp;<LinkContainer className={"hyperlink"} placeholder={"Evidence of Coverage (EOC)"} urlAddress={"EOCUrl"} spaSettings={true} newTab={true}></LinkContainer>
                        &nbsp;for cost-sharing information and more details.<br /><br />

                        For assistance call Alignment Health Plan&apos;s Member Services at 1-866-634-2247 (TTY 711).
                        Hours of Operations:
                        8:00 a.m. to 8:00 p.m., 7 days a week (except Thanksgiving and Christmas) from October 1 through March 31, and Monday to Friday (except holidays) from April 1 through September 30.<br /><br />
                    </p>
                    <div className="donwload-link">
                        <div className="icon" >
                            <Icon icon={'pdf'} />
                        </div>
                        <div className="donwload-link__link">
                            <LinkContainer
                                className={"hyperlink"}
                                placeholder={"Download our provider directory"}
                                urlAddress={"EOCUrl"}
                                spaSettings={true}
                                newTab={true}
                                eventName={AuditEventTypes.ProviderSearchDownloadProviderDirectoryClicked}
                            />
                        </div>
                    </div>
                    <p>
                        To help our members and their care team make more informed choices about their healthcare, select providers are highlighted
                        with a High Quality PCP badge when there is sufficient information to measure performance, incorporating several factors
                        including the provider&apos;s HEDIS, CAHPS, member experience, and Disenrollment performance history.<br /><br />

                        For more information please contact your member experience / partner experience representative.<br /><br />
                    </p>
                    <h4>HMO PLANS</h4>
                    <Icon icon="underline" className="help-subtitle_underline"/>
                    <ul>
                        <li>You will have to choose one of our network providers to be your Primary Care Provider (PCP) who will provide and oversee your care.</li><br></br>
                        <li>For AVA (HMO-POS) products, only Primary Care Providers (PCPs) who offer virtual visits may serve as assigned PCPs. Other PCPs who accept AVA (HMO-POS) support your Virtual PCP but cannot be chosen as your PCP.
                            Use the Visit Type filter and select Virtual to view PCPs to choose from.</li><br></br>
                        <li>You must use network providers to get your medical care and services. The only exceptions are emergencies, urgently needed services when the network is not available (generally, when you are out of the area),
                            out-of-area dialysis services, and cases in which My Choice (HMO) authorizes use of out-of-network providers.</li><br></br>
                        <li>In most cases, care you receive from an out-of-network provider (a provider who is not part of our plan‘s network)
                            will not be covered and may be billed to you.</li><br />
                    </ul>
                    <h4>PPO PLANS</h4>
                    <Icon icon="underline" className="help-subtitle_underline"/>
                    <ul>
                        <li>Except for emergencies, it may cost more to get care from out-of-network providers.</li><br />
                        <li>You may want to check with your plan to see if the services you are getting are covered by our plan and are medically necessary.</li><br />
                        <li>For emergency care, including post stabilization care and out-of-area dialysis, your out-of-pocket costs will be the same for both in and out-of-network.</li><br />
                    </ul>
                    <InternalLinkContainer
                        className='buttonHelp__back'
                        placeholder='Go Back to Provider Search'
                        urlAddress={'/'}
                        underline={false}
                    />
                </div>
            </div>
        </>
    )
}

export default Help;