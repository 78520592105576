import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, getSpecialties, getPlanData } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, tooltipsTxt } from "../../common/filters";
import { ProviderIcon, TooltipContainer, DropdownContainer, Spinner, Icon } from "../../components";
import { specialties, pcpSpecialities, handleFeatureFlagForHardcodingSpecialties } from "../utils";
import { Menus } from "../../common/utils/TrackEventTypes";

const DoctorTypeField = () => {
    const providerData = providerSelector;
    const doctorSpecialtiesData = useSelector(providerData.specialtiesData);
    const selectedDoctorType = useSelector(providerData.selectedDoctorType);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const dispatch = useDispatch();
    const featureFlagForHardcodingSpecialties = handleFeatureFlagForHardcodingSpecialties();

    useEffect(() => {
        if (!doctorSpecialtiesData && featureFlagForHardcodingSpecialties) {
            // we pass a number 2 as param because we want to get the same values as the current PS when searching by specialist
            dispatch(getSpecialties(2));
        }
    }, [doctorSpecialtiesData]);

    const handleChange = (_event: any, newValue: string) => {
        let providerCategory: number[];
        let specialization: string | null;
        let selectedDoctorType = newValue;

        if (newValue === 'All Doctors') {
            providerCategory = [1, 2, 7];
            specialization = null;
        } else if (newValue === 'Primary Care Provider (PCP)') {
            providerCategory = [1];
            specialization = null;
        } else if (newValue === 'Outpatient Mental / Behavioral Health') {
            providerCategory = [7];
            specialization = null;
        } else if (pcpSpecialities.includes(newValue)) {
            providerCategory = [1];
            specialization = newValue;
        } else {
            specialization = newValue;
            providerCategory = [2];
        }


        const response = {
            root: {
                providerCategory,
            },
            guidedSearch: {
                specialization,
                selectedDoctorType
            }
        }
        // to define if we are storing this in the store or if we are going to storage the values using formik/react-hook-form
        dispatch(updateSearch(response))

        if(selectedLocation && selectedCoverageYear) {
            dispatch(getPlanData());
        }
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__placeholder">
                <div className="fieldgroup__placeholder-title">
                    <span>Doctor Type</span>
                    <Icon icon="asterisk" className="fieldgroup__placeholder-asterisk" />
                </div>
                <TooltipContainer
                    position="BottomCenter"
                    content={tooltipsTxt.provider}
                    eventDetails={{
                        menu: Menus.GuidedSearch,
                        subMenu: 'Doctors',
                        toolTipFor: 'Doctor Type',
                        content: tooltipsTxt.provider,
                    }}
                >
                    <div className="fieldgroup__placeholder-icon">
                        {ProviderIcon['tooltip']}
                    </div>
                </TooltipContainer>
            </div>

            <div className={!doctorSpecialtiesData && featureFlagForHardcodingSpecialties ? 'fieldgroup__field_loading' : `fieldgroup__field`}>
                <DropdownContainer
                    id={'doctorDropdown'}
                    className="fieldgroup__select"
                    dataSource={!featureFlagForHardcodingSpecialties ? specialties : doctorSpecialtiesData}
                    placeholder={placeholdersTxt.provider}
                    onChange={handleChange}
                    value={doctorSpecialtiesData || !featureFlagForHardcodingSpecialties ? selectedDoctorType || null : null}
                    dataIsAnObject={false}
                    enabled={!doctorSpecialtiesData && featureFlagForHardcodingSpecialties}
                />
                {
                    !doctorSpecialtiesData && featureFlagForHardcodingSpecialties && (
                        <Spinner size={40}/>
                    )
                }
            </div>
        </div>
    )
}

export default DoctorTypeField;