import React, { FC } from 'react';
import IDisclosureType from '../../models/DisclosureType';
import './Disclosure.scss';

const Disclosure: FC<IDisclosureType> = ({ content, customCssClass }) => {
    if (typeof content === 'string') {
        content = content.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/\\n/g, '<br/>');
        return (
            <div className={customCssClass || 'disclosure-content'} dangerouslySetInnerHTML={{ __html: content }}>
            </div>
        );
    }

    return (
        <div className={customCssClass || 'disclosure-content'}
        >
            {content}
        </div>
    )
}

export default Disclosure