import React from "react";
import Card from '../../common/assets/medicalCard-plan.png';
import "./Dialogs.scss";

const PlanDialog = () => {
    return (
        <div className="dialog__content-padding">
            <p>
                The list of providers that are in-network varies by health plan product. Your plan product name is listed on the top of your ID card.
            </p>

            <figure className="dialog__img-container">
                <img src={Card} alt="Medical Card" className="dialog__img__medical-card"/>
            </figure>

            <p>
                If you specify a health plan product here, you will see the list of providers that are in-network for that specific health plan product.
            </p>
        </div>
    )
}

export default PlanDialog;