import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Error.scss';

function Error() {
    const navigate = useNavigate();

    const goHomeclickHandler = () => {
        navigate({
            pathname: '/',
        }, { replace: true });
    };

    return (
        <div className="error-wrapper">
            <p className="error-title">The specified URL does not exist</p>
            <br />
            <p className="error-content">Are you searching for something? Make sure to include all required parameters.</p>
            <button type="button" onClick={goHomeclickHandler}>Go Home</button>
        </div>
    );
}

export default Error;
