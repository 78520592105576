import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Icon from '../Icons/Icons';
import { IButtonType } from '../models/ButtonType';
import Spinner from '../Spinner/Spinner';

const ButtonContainer: FC<IButtonType> = ({ className, placeholder, onClick, disabled, loading, icon }) => {
    return (
        <Button
            className={className}
            onClick={onClick}
            role="button"
            disabled={disabled}>
            {
                icon && (
                    <Icon icon={icon} />
                )
            }
            {placeholder}
            {
                loading && (
                    <Spinner size={30} />
                )
            }
        </Button>
    )
};

export default ButtonContainer;