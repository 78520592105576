import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { providerSelector } from 'redux/selectors/providerSelectors';
import LocationCard from "./LocationCard";
import AccordionComponent from "../Accordion/AccordionComponent";
import { IAccordionLocation } from "../models/LocationType";
import { IProviderItemType } from "../models/ProviderDataType";

const LocationAccordion = ({ provider }: IProviderItemType) => {
    const providerData = providerSelector;
    const locationAccordionsExpanded = useSelector(providerData.locationAccordionsExpanded);
    const [locations, setLocations] = useState<IAccordionLocation[]>([]);

    useEffect(() => {
        if (provider) {
            const accordionLocations = provider.locations?.map((location) => {
                return {
                    ...location,
                    expanded: locationAccordionsExpanded
                }
            })

            setLocations(accordionLocations);
        }
    }, [locationAccordionsExpanded])

    const getLocations = () => {
        if (locations.length === 0) {
            if (provider) {
                const accordionLocations = provider.locations?.map((location, index) => {
                    return {
                        ...location,
                        expanded: index === 0 ? true : false
                    }
                });
                return accordionLocations;
            }
        }

        return locations;
    }

    return (
        <>
            {getLocations().map((location: IAccordionLocation, index: number) => {
                return (
                    <AccordionComponent
                        id={index}
                        key={index}
                        expanded={location.expanded}
                        locationIndex={index}
                        header={
                            <div className="marker">
                                Location
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z" />
                                    <text x="50%" y="49%" textAnchor="middle" dominantBaseline="middle">{index + 1}</text>
                                </svg>
                            </div>
                        }
                        content={<LocationCard {...location} isPCP={provider?.isPCP} isSpecialist={provider?.isSpecialist} locationIndex={index} />}
                    />
                )
            })}
        </>
    )
}

export default LocationAccordion;