import React from "react";
import Card from '../../common/assets/medicalCard-group.png';
import "./Dialogs.scss";

const MedicalDialog = () => {
    return (
        <div className="dialog__content-padding">
            <p>
                For HMO members, the list of providers that are in-network varies according to the combination of your health plan product and your medical group network. Your medical group name is listed on the top of your ID card.
            </p>

            <figure className="dialog__img-container">
                <img src={Card} alt="Medical Card" />
            </figure>

            <p>
                If you specify a medical group network in this filter, you will see the list of providers that are in-network for your medical group affiliation.
            </p>
            <p>
                If you are a PPO member, the list of in-network providers only depends upon the health plan product, and you do not need to use this filter.
            </p>
        </div>
    )
}

export default MedicalDialog;