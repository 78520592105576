import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { selectProviderType } from 'redux/actions';
import { placeholdersTxt } from '../../common/filters';
import { DropdownContainer } from '../../components';
import { IProviderDropdownType } from '../models/ProviderDropdownType';
import { providerTypes } from '../utils';

const ProviderTypesField: FC<{ selectedProviderType: number }> = ({ selectedProviderType }) => {
    const dispatch = useDispatch();

    const getInitialValue = providerTypes.find((item: IProviderDropdownType) => {
        return item.value === selectedProviderType;
    });

    const handleChange = (_event: any, newValue: IProviderDropdownType) => {
        const id = newValue.value;
        dispatch(selectProviderType(id));
    }

    return (
        <div className="fieldgroup__container">
            <div className="fieldgroup__field">
                <DropdownContainer
                    className="fieldgroup__select"
                    dataSource={providerTypes}
                    placeholder={placeholdersTxt.providerType}
                    onChange={handleChange}
                    value={getInitialValue}
                    dataIsAnObject={true}
                    enabled={false}
                    showClearButton={true}
                />
            </div>
        </div>
    )
}

export default ProviderTypesField;