import React from "react";
import { isEqual } from 'lodash';
import "./PCPDetails.scss";
import { getSpecialities } from '../../common/widgets';
import { ParseAcceptingAVAHmo } from "../avaHmo"
import Icon from "../Icons/Icons";
import PSMap from "../Map/PSMap";
import { IProviderItemType } from "../models/ProviderDataType";
import ReportIncorrectInformation from '../Report/ReportIncorrectInformation';
import { ProviderIcon } from "../Icons/SvgIcons"
import { TooltipContainer } from "../../components";
import { PcpBadge } from "../Tooltip/TooltipContent";
import { AiFillCheckCircle } from "react-icons/ai";
import { MEDICAID_INDICATOR_TEXT } from "../utils";

const PCPDetails = ({ provider }: IProviderItemType) => {
    const markers = provider && provider.locations.length > 0
        ? provider.locations.map((location: any, index: number) => ({ latitude: location.latitude, longitude: location.longitude, title: 'location' + index }))
        : [];
    const isEmptyMapMarkers = (markers: any) => isEqual(markers, [{ "latitude": 0, "longitude": 0, "title": "location0" }]);

    const iconsData = () => (
        <>
            <div className="icons-col__div">
                <div>
                    {ProviderIcon['medicalGroup']}
                    <span>{provider ? provider.numberOfMedicalGroupsThisProviderHas : 0}</span>
                </div>
                <div>Groups</div>
            </div>

            <div className="icons-col__div">
                <div>
                    <Icon icon={'hospital'} />
                    <span>
                        {provider && Array.isArray(provider.locations)
                            ? provider.locations.length
                            : 0}
                    </span>
                </div>
                <div>Locations</div>
            </div>
        </>
    );

    const silverOrGold = (badgeValue: number | undefined) => {
        if (!badgeValue) return <></>
        switch (badgeValue) {
            case 5:
                return <Icon icon={'gold'} />
            case 4:
                return <Icon icon={'silver'} />
            default:
                return <></>
        }
    }
    const getDetailsValues = () => {
        if (!provider?.isPCP && !provider?.isSpecialist) {
            return {
                title: provider?.entityTypeCode == "2" ? 'Facility Type' : 'Service Type',
                value: provider?.classDescription !== 'Hospital' ? provider?.specialityDescription : provider?.specialityDescription.split(',')[0]
            }
        } else {
            return provider.isPCP ? { title: 'PCP', value: getSpecialities(provider?.pcpSpecialities || []) }
                : provider.isSpecialist ? { title: 'Specialist', value: getSpecialities(provider?.specialistSpecialties || []) }
                    : { title: '', value: '' }
        }
    };

    const pcpDetails = getDetailsValues();

    return (
        <div className="PCP_details__div">
            <div className="PCP_details__container">
                <div className="PCP_details__text">
                    <div className="PCP_details__name font-cndm">
                        {provider &&
                            <>
                                {
                                    provider.isPCP ?
                                        <div className='innerProvider'>
                                            {provider.firstName} {provider.middleName} {provider.lastName}, {provider.suffix}
                                            {
                                                provider?.badge >= 4 && (
                                                    <TooltipContainer
                                                        position="Center"
                                                        content={<PcpBadge />}
                                                        eventDetails={{
                                                            menu: 'PCP Details',
                                                            toolTipFor: 'Provider Badge - PCP Details',
                                                            content: 'PcpBadge',
                                                        }}
                                                    >
                                                        <span className='provider__badge'>
                                                            {
                                                                silverOrGold(provider?.badge)
                                                            }
                                                        </span>
                                                    </TooltipContainer>
                                                )
                                            }
                                        </div>
                                        :
                                        <>
                                            {
                                                provider.firstName ? (
                                                    <>{provider.firstName} {provider.middleName} {provider.lastName}, {provider.suffix}</>
                                                ) : (
                                                    <>{provider.organizationName}</>
                                                )
                                            }
                                        </>
                                }
                            </>
                        }
                    </div>
                    <div className="PCP_details__text">
                        {provider &&
                            <>
                                <p>
                                    <span className="font-demi">NPI: </span>
                                    <span className="font-book">{provider.npi}</span>
                                </p>
                                <p>
                                    <span className="font-demi">{pcpDetails.title}: </span>
                                    <span className="font-book">{pcpDetails.value}</span>
                                </p>
                                <p>
                                    <span className="font-demi">Language: </span>
                                    <span className="font-book">
                                        {provider.language}
                                        {provider.otherLanguages && provider.otherLanguages.length > 0 && (`, ${provider.otherLanguages.join(', ')}`)}
                                    </span>
                                </p>

                                {provider.gender && (
                                    <p>
                                        <span className="font-demi">Gender: </span>
                                        <span className="font-book">{provider.gender}</span>
                                    </p>
                                )}

                                {
                                    (provider.isPCP || provider.isSpecialist) && (
                                        <div className="font-demi">
                                            <p>{provider.medicalGroups[0].panelDescription}</p>
                                            <p className="PCP_details__ava_hmo"><strong><ParseAcceptingAVAHmo locations={provider.locations} /></strong></p>
                                            {provider.isMedicaidRegistered && (
                                                <p className='icon__container'>
                                                    <AiFillCheckCircle className='check__icon'/>
                                                    {' '}
                                                    {MEDICAID_INDICATOR_TEXT}
                                                </p>
                                            )}
                                        </div>
                                    )
                                }
                            </>
                        }
                        <div className="icons-data">
                            {iconsData()}
                        </div>
                    </div>
                </div>
                <div className="mapCol">
                    <div className="mockupMap">
                        {!isEmptyMapMarkers(markers) &&
                            <div className="pcp__map">
                                <PSMap providerMarkers={markers} needDirections={false} getDirections={(_response: any) => { }} />
                            </div>
                        }
                    </div>
                    <ReportIncorrectInformation provider={provider} />
                </div>
            </div>
        </div>
    )
}

export default PCPDetails;