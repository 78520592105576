import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { updateSearch, refreshPlanNetwork } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { placeholdersTxt, mapRequiredSelector } from "../../common/filters";
import { ButtonContainer, DropdownContainer, DialogContainer, Spinner } from "../../components";
import MedicalDialog from "../Dialog/MedicalDialog";
import useTrackEvent from "../../common/utils/useTrackEvent";
import { AuditEventTypes, Menus, ProviderType, SubMenus } from "../../common/utils/TrackEventTypes";

const MedicalGroupField = () => {
    const [showModal, setshowModal] = useState(false);
    const [disabledForm, setDisabledForm] = useState(true);
    const [medicalValue, setMedicalValue] = useState('');
    const dispatch = useDispatch();

    const providerData = providerSelector;
    const providerType = useSelector(providerData.selectedProviderType);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const selectedType = useSelector(providerData[mapRequiredSelector[providerType]]);
    const medicalGroupData = useSelector(providerData.medicalGroupsData);
    const selectedMedicalGroup = useSelector(providerData.selectedMedicalGroup);
    const selectedPlan = useSelector(providerData.selectedPlan);
    const isLoading = useSelector(providerData.isLoadingData);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchWhySelectMedicalGroupClicked,
        { menu: Menus.GuidedSearch, subMenu: SubMenus[providerType as ProviderType] }
    );

    useEffect(() => {
        if (selectedLocation && selectedType && selectedCoverageYear) {
            setDisabledForm(false);
        } else {
            setDisabledForm(true);
        }
    }, [selectedLocation, selectedType, selectedCoverageYear]);

    useEffect(() => {
        if (disabledForm) {
            setMedicalValue('')
        } else {
            setMedicalValue(selectedMedicalGroup ?? '')
        }
    }, [disabledForm, selectedMedicalGroup])

    const handleModal = () => {
        if (!showModal) {
            trackEvent();
        }
        setshowModal(!showModal)
    }

    const headerTemplate = () => {
        return (
            <div className='dialog__header'>
                <div className='dialog__header-title'>
                    Why Should I Select a Medical Group?
                </div>
                <button className='dialog__header-btn' onClick={handleModal}>
                    <CancelIcon sx={{ fontSize: 38.54 }} />
                </button>
            </div>

        )
    }

    const footerTemplate = () => {
        return (
            <div>
                <ButtonContainer
                    className='dialog__container-btn'
                    placeholder='Close'
                    onClick={handleModal}
                />
            </div>
        );
    }

    const handleChange = (_event: any, newValue: string | any, reason: any) => {
        if (reason === 'clear') {
            dispatch(updateSearch({
                guidedSearch: {
                    selectedMedicalGroup: null
                }
            }));
            return;
        }
        if (selectedPlan) {
            dispatch(updateSearch({
                guidedSearch: { selectedMedicalGroup: newValue }
            }));
        } else {
            dispatch(refreshPlanNetwork({
                root: { planData: null },
                guidedSearch: {
                    selectedMedicalGroup: newValue
                }
            }))
        }
    };

    return (
        <>
            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <div className="fieldgroup__placeholder-title">
                        Member Medical Group Network (optional)

                        <ButtonContainer
                            className='fieldgroup__help'
                            placeholder='Why should I select a medical group?'
                            onClick={handleModal}
                        />
                    </div>
                </div>

                <div className={isLoading && !selectedMedicalGroup ? 'fieldgroup__field_loading' : `fieldgroup__field`}>
                    <DropdownContainer
                        id={'MedicalGrpDropdown'}
                        className="fieldgroup__select"
                        dataSource={medicalGroupData}
                        placeholder={placeholdersTxt.medicalGroup}
                        onChange={handleChange}
                        enabled={!medicalGroupData || disabledForm}
                        value={medicalValue}
                        showClearButton={false}
                        dataIsAnObject={false}
                    />
                    {
                        isLoading && !selectedMedicalGroup && (
                            <Spinner size={40} />
                        )
                    }
                </div>
            </div>

            <DialogContainer
                content={MedicalDialog}
                header={headerTemplate}
                footer={footerTemplate}
                visible={showModal}
            />
        </>
    )
}

export default MedicalGroupField;