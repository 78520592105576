import React from "react";
import { useSelector } from "react-redux";
import { providerSelector } from 'redux/selectors/providerSelectors';
import LinkContainer from "../Link/LinkContainer";
import { checkIfIsNcNv23 } from "../utils";
import './PlanDisclaimer.scss';

const VisualDentalDisclaimer = () => {
    const providerData = providerSelector;
    const selectedProviderType = useSelector(providerData.selectedProviderType);
    const selectedUSAState = useSelector(providerData.selectedUSAState);
    const selectedCorevageYear = useSelector(providerData.selectedCoverageYear)
    return (
        <div className="vd_disclaimer__container">
            <div className="vd_disclaimer__content">
                {
                    selectedProviderType === 3 ?
                        <>
                            {
                                !checkIfIsNcNv23(selectedCorevageYear, selectedUSAState) && (<>
                                    Benefits vary by plan.&nbsp;
                                </>)
                            }
                            Refer to your <LinkContainer className={"hyperlink"} placeholder={"Evidence of Coverage (EOC)"} urlAddress={"EOCUrl"} spaSettings={true} newTab={true}></LinkContainer> for more details. <br />If you need assistance, call Alignment Health Plan&apos;s dental customer service line at (866) 454-3008 (TTY 711)<br /> 24 hours a day, seven days a week (excluding holidays)
                        </>
                        :
                        <>
                            Benefits vary by plan. Refer to your <LinkContainer className={"hyperlink"} placeholder={"Evidence of Coverage (EOC)"} urlAddress={"EOCUrl"} spaSettings={true} newTab={true}></LinkContainer> for more details. <br />If you need assistance, call VSP at 1-833-413-9748 (TTY 711), 24 hours a day, seven days a week (excluding holidays)
                        </>
                }
                .
            </div>
        </div>
    )
}

export default VisualDentalDisclaimer;