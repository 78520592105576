import React from 'react';
import { TextField, InputLabel } from '@mui/material';
import { placeholdersTxt } from "../../common/filters";

const SendEmailWithIcon = ({handleChange}): any => {
    return (
        <div className='send-email__content'>
            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <InputLabel className="fieldgroup__placeholder-title" id="emailName">Name*</InputLabel>
                </div>

                <div className="fieldgroup__field">
                    <TextField onChange={handleChange} name="emailName" aria-labelledby="emailName" className="fieldgroup__field-input" placeholder={placeholdersTxt.emailName} variant='standard' fullWidth/>
                </div>
            </div>

            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <InputLabel className="fieldgroup__placeholder-title" id="emailAddress">Email Address*</InputLabel>
                </div>

                <div className="fieldgroup__field">
                    <TextField onChange={handleChange} name="emailAddress" aria-labelledby="emailAddress" className="fieldgroup__field-input" placeholder={placeholdersTxt.emailAddress} variant='standard' fullWidth/>
                </div>
            </div>

            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <InputLabel className="fieldgroup__placeholder-title" id="emailMessage">Message Optional</InputLabel>
                </div>

                <div className="fieldgroup__field">
                    <TextField className="fieldgroup__field-input" id="reportMessage" aria-labelledby="emailMessage" name="emailMessage"  variant="standard" fullWidth multiline rows={3} color='secondary' onChange={handleChange}/>
                </div>
            </div>
        </div>
    );
}

export default SendEmailWithIcon;