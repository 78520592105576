import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ISelectType } from '../models/SelectType';

const SelectContainer: FC<ISelectType> = ({className, dataSource, placeholder, onChange, value, dataIsAnObject}) => {
    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value)
    }
    return (
        <FormControl fullWidth className={className}>
            <InputLabel id={placeholder?.replace(' ', '-')}>{placeholder}</InputLabel>
            <Select
                labelId={placeholder?.replace(' ', '-')}
                value={value}
                label={placeholder}
                onChange={handleChange}
            >
                {
                    dataSource?.map((item: any) => (
                        <MenuItem key={dataIsAnObject ? item.value : item} value={dataIsAnObject ? item.value : item}>
                            {dataIsAnObject ? item.text : item}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default SelectContainer