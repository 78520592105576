import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearFilters } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import FiltersDoctor from './FiltersDoctor';
import { isMobile } from '../../common/widgets'
import { ButtonContainer } from "../../components";
import MedicalAffiliationField from '../Fields/MedicalAffiliationField';
import ServicesField from '../Fields/ServicesField';
import './FiltersSearchContainer.scss';

const FiltersSearchContainer = ({ selectedProviderType }: { selectedProviderType: number; }) => {
    const selector = providerSelector;
    const selectedFacilityType = useSelector(selector.selectedFacilityType);
    const dispatch = useDispatch();


    const handleClear = () => {
        const cleanFiltersState = {
            selectedVisitType: null,
            selectedLanguage: null,
            selectedGender: null,
            selectedAcceptNewPatients: null,
            selectedProviderAffiliation: null,
            selectedServiceType: null,
        }

        dispatch(clearFilters(cleanFiltersState));
    }

    return (
        <div className='filters-search__container'>
            { isMobile() &&
                <div className="filters-search__title">
                    <p><b>Filter Your Search (Optional)</b></p>
                </div>
            }
            <div className='filters-container'>
                {
                    selectedProviderType === 1 && (
                        <FiltersDoctor />
                    )
                }

                {
                    selectedProviderType === 2 && selectedFacilityType === "Hospital" && (
                        <ServicesField />
                    )
                }

                <div className='medical-clear__container'>
                    <div className='medical__container'>
                        <MedicalAffiliationField />
                    </div>

                    <div className='clear__container'>
                        <ButtonContainer
                            className='fieldgroup__clear'
                            placeholder='Clear filters'
                            onClick={handleClear}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltersSearchContainer;