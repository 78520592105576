import React, { FC, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { ILocationType } from '../models/LocationType';
import { mapContainerStyle } from '../utils';

interface IPSMap {
    providerMarkers: any,
    needDirections: boolean,
    getDirections: (response: any) => void,
    originPoint?: string
}

const PSMap: FC<IPSMap> = ({ providerMarkers, needDirections, getDirections, originPoint }) => {
    const [noZeroMarkers, setNoZeroMarkers] = useState([])

    const [directions, setDirections] = useState(null);
    const [gotDirections, setGotDirections] = useState(false);

    useEffect(() => {
        if (providerMarkers.length > 0 && noZeroMarkers.length <= 0) {
            const temp = providerMarkers.filter((element: ILocationType) => element['latitude'] !== 0 && element['longitude'] !== 0) as any;
            setNoZeroMarkers(temp);
        }
    }, [providerMarkers, noZeroMarkers]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCZUXjld2wi_EmRj5H4Y6idtjx5lkvrjn4"
    })

    const directionsCallBack = (response: any) => {
        setDirections(() => response);
        setGotDirections(true)
        getDirections(response)
    }

    const renderMap = () => {
        const bounds = new window.google.maps.LatLngBounds();

        if (noZeroMarkers.length > 1) {
            noZeroMarkers.map((location: ILocationType) => bounds.extend({
                lat: location['latitude'], lng: location['longitude']
            })
            );
        }

        const latitude = noZeroMarkers[0]['latitude']
        const longitude = noZeroMarkers[0]['longitude']
        return (
            needDirections ?
                <GoogleMap
                    center={{
                        lat: latitude,
                        lng: longitude
                    }}
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    onLoad={noZeroMarkers.length > 1 ? (map: any) => map.fitBounds(bounds) : () => { }}>
                    {
                        !gotDirections && (
                            <DirectionsService
                                callback={directionsCallBack}
                                options={
                                    {
                                        destination: {
                                            lat: latitude,
                                            lng: longitude
                                        },
                                        origin: originPoint ?? '',
                                        travelMode: google.maps.TravelMode.DRIVING,
                                    }
                                }
                            />
                        )
                    }
                    {
                        directions !== null && (
                            <DirectionsRenderer options={{ directions: directions }} />
                        )
                    }
                </GoogleMap>
                :
                <GoogleMap
                    center={{
                        lat: latitude,
                        lng: longitude
                    }}
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    onLoad={noZeroMarkers.length > 1 ? (map: any) => map.fitBounds(bounds) : () => { }}>
                    {
                        noZeroMarkers.length > 0 ? noZeroMarkers.map((location: ILocationType, index: number) => {
                            return (
                                <Marker
                                    key={location['title']}
                                    position={{
                                        lat: location['latitude'],
                                        lng: location['longitude']
                                    }}
                                    label={{
                                        color: '#FFFFFF',
                                        fontFamily: 'Raleway',
                                        fontSize: '24px',
                                        fontWeight: '800',
                                        text: `${index + 1}`
                                    }}
                                />
                            )
                        })
                            : <></>
                    }
                </GoogleMap>
        )
    }

    return noZeroMarkers.length > 0 && isLoaded ? renderMap() : <></>
}

export default React.memo(PSMap)