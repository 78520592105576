import React from "react";

export const GoldBadge = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="64.004" height="78.968" viewBox="12 5 40.004 61.968">
            <defs>
                <filter id="Union_3" x="0" y="0" width="64.004" height="63.661" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feFlood floodOpacity="0.102" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <filter id="Subtraction_3" x="7.667" y="43.487" width="48.676" height="35.481" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" result="blur-2" />
                    <feFlood floodOpacity="0.102" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Group_21221" data-name="Group 21221" transform="translate(-1843 -1900.469)">
                <g transform="matrix(1, 0, 0, 1, 1843, 1900.47)" filter="url(#Union_3)">
                    <path id="Union_3-2" data-name="Union 3" d="M18.259,43.837a6.765,6.765,0,0,1-5.079-.317A6.586,6.586,0,0,1,9.668,39.9,6.69,6.69,0,0,1,5.2,37.506a6.479,6.479,0,0,1-1.625-4.74A6.563,6.563,0,0,1,.55,28.739a6.441,6.441,0,0,1,.567-4.969,6.45,6.45,0,0,1-1-4.9A6.519,6.519,0,0,1,2.765,14.6a6.445,6.445,0,0,1,1.2-4.861A6.674,6.674,0,0,1,8.2,6.966,6.561,6.561,0,0,1,11.38,3.059a6.419,6.419,0,0,1,6.13-.313l.022,0A6.355,6.355,0,0,1,23,0a6.727,6.727,0,0,1,4.743,1.823,6.766,6.766,0,0,1,5.078.315A6.587,6.587,0,0,1,36.337,5.76a6.7,6.7,0,0,1,4.469,2.393,6.478,6.478,0,0,1,1.625,4.74,6.56,6.56,0,0,1,3.022,4.027,6.434,6.434,0,0,1-.567,4.97,6.445,6.445,0,0,1,1,4.9,6.52,6.52,0,0,1-2.651,4.271,6.446,6.446,0,0,1-1.2,4.86,6.667,6.667,0,0,1-4.24,2.768A6.565,6.565,0,0,1,34.624,42.6a6.427,6.427,0,0,1-6.144.306A6.353,6.353,0,0,1,23,45.661,6.725,6.725,0,0,1,18.259,43.837Z" transform="translate(9 6)" fill="#f89c1d" />
                </g>
                <circle id="Ellipse_56" data-name="Ellipse 56" cx="16.431" cy="16.431" r="16.431" transform="translate(1858.573 1912.992)" fill="#f5f5f8" />
                <g transform="matrix(1, 0, 0, 1, 1843, 1900.47)" filter="url(#Subtraction_3)">
                    <path id="Subtraction_3-2" data-name="Subtraction 3" d="M30.676,17.481h-.315L23,14.888V3.477c.592-.126,1.174-.269,1.734-.426A13.3,13.3,0,0,0,30.676,0V17.48Zm-30.356,0H0V0A13.314,13.314,0,0,0,5.937,3.051c.551.154,1.133.3,1.73.425V14.889L.32,17.481Zm18.3-4.137h0l-3.284-1.159-3.285,1.159,0-9.236c1.079.088,2.185.133,3.29.133s2.209-.045,3.282-.132v9.235Z" transform="translate(16.67 49.49)" fill="#f89c1d" />
                </g>
                <text id="_5" data-name="5" transform="translate(1865.428 1941.146)" fill="#f89c1d" stroke="#f89c1d" strokeWidth="1" fontSize="32" fontFamily="Raleway, sans-serif" fontWeight="700"><tspan x="1" y="-4">5</tspan></text>
            </g>
        </svg>
    )
}