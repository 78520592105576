import { IWitResponse } from 'ProviderSearch.CoreLibraries/components/models/WitResponse';
import { IEmail } from '../reducers/models/EmailType';

export enum ProviderSearchActions {
    CLEAR_STATE = 'CLEAR_STATE',
    SELECT_PROVIDER_TYPE = 'SELECT_PROVIDER_TYPE',
    SEARCH_PROVIDERS = 'SEARCH_PROVIDERS',
    SEARCH_PROVIDERS_SUCCESS = 'SEARCH_PROVIDERS_SUCCESS',
    SEARCH_PROVIDERS_ERROR = 'SEARCH_PROVIDERS_ERROR',
    QUICK_SEARCH = 'QUICK_SEARCH',
    QUICK_SEARCH_SUCCESS = 'QUICK_SEARCH_SUCCESS',
    QUICK_SEARCH_ERROR = 'QUICK_SEARCH_ERROR',
    GET_SPECIALTIES = 'GET_SPECIALTIES',
    GET_SPECIALTIES_SUCCESS = 'GET_SPECIALTIES_SUCCESS',
    GET_SPECIALTIES_ERROR = 'GET_SPECIALTIES_ERROR',
    SELECT_FACILITY_TYPE = 'SELECT_FACILITY_TYPE',
    SELECT_LOCATION = 'SELECT_LOCATION',
    UPDATE_FILTERS = 'UPDATE_FILTERS',
    UPDATE_SEARCH = 'UPDATE_SEARCH',
    UPDATE_QUICK_SEARCH = 'UPDATE_QUICK_SEARCH',
    GET_PLAN_DATA = 'GET_PLAN_DATA',
    REFRESH_PLAN_NETWORK = 'REFRESH_PLAN_NETWORK',
    POST_EMAIL = 'POST_EMAIL',
    POST_EMAIL_SUCCESS = 'POST_EMAIL_SUCCESS',
    POST_EMAIL_ERROR = 'POST_EMAIL_ERROR',
    GET_PROVIDER_DETAILS = 'GET_PROVIDER_DETAILS',
    GET_PROVIDER_DETAILS_BY_PROVIDERID = 'GET_PROVIDER_DETAILS_BY_PROVIDERID',
    CHANGE_INDEX_PAGE = 'CHANGE_INDEX_PAGE',
    RESET_INDEX_PAGE = 'RESET_INDEX_PAGE',
    SORT_BY = 'SORT_BY',
    CHANGE_RADIO = 'CHANGE_RADIO',
    CLEAR_FILTERS = 'CLEAR_FILTERS',
    GET_LANGUAGES = 'GET_LANGUAGES',
    GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS',
    GET_LANGUAGES_ERROR = 'GET_LANGUAGES_ERROR',
    SET_COMMING_BACK = 'SET_COMMING_BACK',
    GET_PROVIDER_SERVICES = 'GET_PROVIDER_SERVICES',
    GET_PROVIDER_SERVICES_SUCCESS = 'GET_PROVIDER_SERVICES_SUCCESS',
    GET_PROVIDER_SERVICES_ERROR = 'GET_PROVIDER_SERVICES_ERROR',
    CLEAR_MESSAGES = 'CLEAR_MESSAGES',
    POST_INCORRECT_INFO = 'POST_INCORRECT_INFO',
    POST_INCORRECT_INFO_SUCCESS = 'POST_INCORRECT_INFO_SUCCESS',
    POST_INCORRECT_INFO_ERROR = 'POST_INCORRECT_INFO_ERROR',
    GET_PLANYEARSWITCH = 'GET_PLANYEARSWITCH',
    GET_PLANYEARSWITCH_SUCCESS = 'GET_PLANYEARSWITCH_SUCCESS',
    GET_PLANYEARSWITCH_ERROR = 'GET_PLANYEARSWITCH_ERROR',
    TAB_INDEX = 'TAB_INDEX',
    ASK_CHATBOT = 'ASK_CHATBOT',
    ASK_CHATBOT_RESPONSE = 'ASK_CHATBOT_RESPONSE',
    EXPAND_COLLAPSE_ALL_ACCORDIONS = 'EXPAND_COLLAPSE_ALL_ACCORDIONS',
    EXPAND_COLLAPSE_SECONDARY_ACCORDIONS = 'EXPAND_COLLAPSE_SECONDARY_ACCORDIONS',
    EXPAND_ALL_LOCATION_ACCORDIONS = 'EXPAND_ALL_LOCATION_ACCORDIONS',
}

export const clearState = () => ({
    type: ProviderSearchActions.CLEAR_STATE
});

export const selectProviderType = (id: number) => ({
    type: ProviderSearchActions.SELECT_PROVIDER_TYPE,
    payload: id
});

export const searchProvider = (status: number) => ({
    type: ProviderSearchActions.SEARCH_PROVIDERS,
    payload: status
});

export const searchProviderSuccess = (response: any) => ({
    type: ProviderSearchActions.SEARCH_PROVIDERS_SUCCESS,
    payload: response
});

export const searchProviderError = (error: string) => ({
    type: ProviderSearchActions.SEARCH_PROVIDERS_ERROR,
    payload: error
});

export const quickSearch = (status: number) => ({
    type: ProviderSearchActions.QUICK_SEARCH,
    payload: status
});

export const quickSearchSuccess = (response: any) => ({
    type: ProviderSearchActions.QUICK_SEARCH_SUCCESS,
    payload: response
});

export const quickSearchError = (error: string) => ({
    type: ProviderSearchActions.QUICK_SEARCH_ERROR,
    payload: error
});

export const getSpecialties = (providerType: number) => ({
    type: ProviderSearchActions.GET_SPECIALTIES,
    payload: providerType
});

export const getSpecialtiesSuccess = (response: any) => ({
    type: ProviderSearchActions.GET_SPECIALTIES_SUCCESS,
    payload: response
});

export const getSpecialtiesError = (error: string) => ({
    type: ProviderSearchActions.GET_SPECIALTIES_ERROR,
    payload: error
});

export const updateFilters = (response: any) => ({
    type: ProviderSearchActions.UPDATE_FILTERS,
    payload: response
});

export const updateSearch = (response: any) => ({
    type: ProviderSearchActions.UPDATE_SEARCH,
    payload: response,
});

export const updateQuickSearch = (response: any) => ({
    type: ProviderSearchActions.UPDATE_QUICK_SEARCH,
    payload: response,
});

export const getPlanData = () => ({
    type: ProviderSearchActions.GET_PLAN_DATA
});

export const refreshPlanNetwork = (payload: any) => ({
    type: ProviderSearchActions.REFRESH_PLAN_NETWORK,
    payload
});

export const postEmail = (emailDetails: IEmail) => ({
    type: ProviderSearchActions.POST_EMAIL,
    payload: emailDetails
});

export const postEmailSuccess = (response: any) => ({
    type: ProviderSearchActions.POST_EMAIL_SUCCESS,
    payload: response
});

export const postEmailError = (error: string) => ({
    type: ProviderSearchActions.POST_EMAIL_ERROR,
    payload: error
});

export const getProviderDetails = (npi: string) => ({
    type: ProviderSearchActions.GET_PROVIDER_DETAILS,
    payload: npi
});

export const getProviderDetailsByProviderId = (providerId: string) => ({
    type: ProviderSearchActions.GET_PROVIDER_DETAILS_BY_PROVIDERID,
    payload: providerId
});

export const changeIndexPage = (index: number, searchType: string) => ({
    type: ProviderSearchActions.CHANGE_INDEX_PAGE,
    payload: index,
    searchType
})

export const resetIndexPage = (searchType: string) => ({
    type: ProviderSearchActions.RESET_INDEX_PAGE,
    searchType
})

export const sortBy = (value: string, searchType: string) => ({
    type: ProviderSearchActions.SORT_BY,
    payload: value,
    searchType
});

export const changeRadio = (value: number, searchType: string) => ({
    type: ProviderSearchActions.CHANGE_RADIO,
    payload: value,
    searchType
});

export const clearFilters = (filters: any) => ({
    type: ProviderSearchActions.CLEAR_FILTERS,
    payload: filters
});

export const getLanguages = () => ({
    type: ProviderSearchActions.GET_LANGUAGES,
});

export const getLanguagesSuccess = (response: any) => ({
    type: ProviderSearchActions.GET_LANGUAGES_SUCCESS,
    payload: response
});

export const getLanguagesError = (error: string) => ({
    type: ProviderSearchActions.GET_LANGUAGES_ERROR,
    payload: error
});

export const setCommingBack = () => ({
    type: ProviderSearchActions.SET_COMMING_BACK
});

export const getProviderServices = () => ({
    type: ProviderSearchActions.GET_PROVIDER_SERVICES,
});

export const getProviderServicesSuccess = (response: any) => ({
    type: ProviderSearchActions.GET_PROVIDER_SERVICES_SUCCESS,
    payload: response
});

export const getProviderServicesError = (error: string) => ({
    type: ProviderSearchActions.GET_PROVIDER_SERVICES_ERROR,
    payload: error
});

export const clearMessages = () => ({
    type: ProviderSearchActions.CLEAR_MESSAGES,
});

export const postIncorrectInformation = (incorrectInfo: any) => ({
    type: ProviderSearchActions.POST_INCORRECT_INFO,
    payload: incorrectInfo
})

export const postIncorrectInformationSuccess = (response: any) => ({
    type: ProviderSearchActions.POST_INCORRECT_INFO_SUCCESS,
    payload: response
});

export const postIncorrectInformationError = (error: any) => ({
    type: ProviderSearchActions.POST_INCORRECT_INFO_ERROR,
    payload: error
});

export const getPlanYearSwitch = () => ({
    type: ProviderSearchActions.GET_PLANYEARSWITCH
});

export const getPlanYearSwitchSuccess = (response: any) => ({
    type: ProviderSearchActions.GET_PLANYEARSWITCH_SUCCESS,
    payload: response
})

export const getPlanYearSwitchError = (error: any) => ({
    type: ProviderSearchActions.GET_PLANYEARSWITCH_ERROR,
    payload: error
});

export const setTabIndex = (index: number) => ({
    type: ProviderSearchActions.TAB_INDEX,
    payload: index
});

export const askChatbot = (payload: {message: string[], status: string}) => ({
    type: ProviderSearchActions.ASK_CHATBOT,
    payload
})

export const askChatbotResponse = (payload: IWitResponse) => ({
    type: ProviderSearchActions.ASK_CHATBOT_RESPONSE,
    payload
})

export const expandCollapseAllAccordions = (isExpanded: boolean | null) => ({
    type: ProviderSearchActions.EXPAND_COLLAPSE_ALL_ACCORDIONS,
    payload: isExpanded
})

export const expandCollapseSecondaryAccordions = (isExpanded: Boolean) => ({
    type: ProviderSearchActions.EXPAND_COLLAPSE_SECONDARY_ACCORDIONS,
    payload: isExpanded
})

export const expandAllLocationAccordions = (expanded: boolean) => ({
    type: ProviderSearchActions.EXPAND_ALL_LOCATION_ACCORDIONS,
    payload: expanded
})