import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters, getLanguages } from "redux/actions";
import { providerSelector } from "redux/selectors/providerSelectors";
import { placeholdersTxt } from "../../common/filters";
import { DropdownContainer,TooltipContainer,ProviderIcon } from "../../components";

const LanguageField = () => {
    const selector = providerSelector;
    const selectedLanguage = useSelector(selector.selectedLanguage);
    const languagesData = useSelector(selector.languagesData);
    const dispatch = useDispatch();

    const handleChange = (_event: any, newValue: String) => {
        dispatch(updateFilters({selectedLanguage:newValue}))
    }

    useEffect(() => {
        if(!languagesData) {
            dispatch(getLanguages());
        }
    }, [languagesData]);

    return (
        <>
            <div className="fieldgroup__container">
                <div className="fieldgroup__placeholder">
                    <div className="fieldgroup__placeholder-title">
                        Language
                    </div>
                    <TooltipContainer
                        position="BottomCenter" content={""} disableHover={true}
                    >
                        <div className="fieldgroup__placeholder-icon">
                            { ProviderIcon['tooltip']}
                        </div>
                    </TooltipContainer>
                </div>

                <div className="fieldgroup__field">
                    <DropdownContainer
                        id={'LanguageDropdown'}
                        className="fieldgroup__select"
                        dataSource={languagesData}
                        placeholder={placeholdersTxt.default}
                        value={selectedLanguage}
                        onChange={handleChange}
                        dataIsAnObject={false}
                        enabled={false}
                    />
                </div>
            </div>
        </>
    )
}

export default LanguageField;