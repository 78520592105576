import React, { FC, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setCommingBack } from "redux/actions";
import SendEmail from '../Email/SendEmail';
import { InternalLinkContainer, ButtonContainer } from "../index";
import { IBackToSearchResultsType } from '../models/BackToSearchResultsType';
import { printDocument } from '../utils';
import '../print.css';
import './BackToSearchResults.scss';
import useTrackEvent from '../../common/utils/useTrackEvent';
import { AuditEventTypes } from '../../common/utils/TrackEventTypes';

const BackToSearchResults: FC<IBackToSearchResultsType> = ({ displayIcons, placeholder, selectedProvider, eventDetails }) => {
    const dispatch = useDispatch();

    const trackPrintEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchPrintButtonClicked,
        eventDetails
    );

    useEffect(() => {
        dispatch(setCommingBack())
    }, []);

    return (
        <div className="back-to-provider__div">
            <div className="back-to-provider__container">
                <InternalLinkContainer
                    className="back-to-provider__button"
                    placeholder={<><span style={{ fontSize: '32px' }}>&#8249;</span> {placeholder}</>}
                    urlAddress={'/'}
                    underline={false}
                />
                {displayIcons &&
                    <div className='back-to-provider__icons-data'>
                        <SendEmail
                            iconName="email"
                            selectedProvider={selectedProvider}
                            eventDetails={eventDetails}
                        />
                        <ButtonContainer
                            className='send-email__container'
                            placeholder='Print'
                            onClick={() => {
                                printDocument();
                                trackPrintEvent();
                            }}
                            icon="print"
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default BackToSearchResults;
