import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { providerSelector } from "redux/selectors/providerSelectors";
import ButtonContainer from './ButtonContainer';
import './GoTopButton.scss';
import { isMobile } from '../../common/widgets'

interface GoTopButtonProps {
    desktopCondition: boolean;
    mobileCondition: boolean;
    showFrom?: number | null;
    showUntil?: number | null;
    mobileScrollTo?: number;
    desktopScrollTo?: number;
}

const GoTopButton: FC<GoTopButtonProps> = ({ desktopCondition = true, mobileCondition = true, showFrom = null, showUntil = null, mobileScrollTo = 0, desktopScrollTo = 0 }: GoTopButtonProps) => {
    const [showGoTop, setShowGoTop] = useState(false);
    const selector = providerSelector;
    const providers = useSelector(selector.providers);
    const rootElement = document.documentElement;
    var scrollFrom = showFrom ?? rootElement.clientHeight;
    var scrollUntil = showUntil ?? rootElement.scrollHeight;

    useEffect(() => {
        if (isMobile()) {
            return;
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isMobile(), providers]);


    const handleScroll = () => {
        if (!showUntil && scrollUntil < rootElement.scrollHeight) {
            scrollUntil = rootElement.scrollHeight;
        }

        if ((rootElement.scrollTop > scrollFrom) && (rootElement.scrollTop < scrollUntil)) {
            setShowGoTop(true);
            handlebuttonPosition();
        } else {
            setShowGoTop(false);
        }
    }

    const handlebuttonPosition = () => {
        const totopButton = document.querySelector(".providerResult__goTop") as HTMLElement;
        var footer = document.querySelector("footer") as HTMLElement;

        // Get the max position to which the button can move
        var maxScrollPosition = document.body.scrollHeight - window.innerHeight - footer.clientHeight;

        window.addEventListener("scroll", function () {
            var currentScrollPosition = window.scrollY;

            // Adjust the vertical position to prevent overlapping with the footer
            if (currentScrollPosition > maxScrollPosition) {
                totopButton.style.bottom = (currentScrollPosition - maxScrollPosition + 15) + "px";
            } else {
                totopButton.style.bottom = "15px";
            }
        });
    };

    const handleClick = () => {
        window.scrollTo({
            top: isMobile() ? mobileScrollTo : desktopScrollTo,
            behavior: 'smooth',
        });
    };

    return (
        <>
            {((!isMobile() && showGoTop && desktopCondition) || (isMobile() && mobileCondition)) && (
                <ButtonContainer
                    className={'providerResult__goTop'}
                    placeholder='To Top'
                    onClick={handleClick}
                    icon="scrollTop"
                />
            )}
        </>
    );
};

export default GoTopButton;