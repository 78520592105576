import React from 'react'

function PcpBadge() {
    return (
        <>
            <span className="badge-tooltip-content">
                High Quality PCP badge is a calculation of several factors including the provider’s HEDIS, CAHPS, member experience, and Disenrollment performance history.
                <br /><br />
                For more information please contact your member experience / partner experience representative.
            </span>
        </>
    )
}

export { PcpBadge };