import React, { FC } from 'react';
import {AiFillCheckCircle, AiFillExclamationCircle,} from 'react-icons/ai';
import { hasPbpWithAvaHmo, ParseAcceptingAVAHmo } from '../avaHmo';
import { IAcceptingConditions } from '../models/AcceptionConditionsType';
import { MEDICAID_INDICATOR_TEXT } from '../utils';

const AcceptingConditions: FC<IAcceptingConditions> = ({locations, newPatients, isMedicaidRegistered}) => {
    return (
        <div className='provider__labels'>
            <p className='icon__container'>
                {
                    newPatients ? (
                        <>
                            <AiFillCheckCircle className='check__icon'/> {locations && locations[0].medicalGroupsParticipated[0].panelDescription}
                        </>
                    ) : (
                        <>
                            <AiFillExclamationCircle className='exclamation__icon'/> {locations && locations[0].medicalGroupsParticipated[0].panelDescription}
                        </>
                    )
                }
            </p>
            <p className='icon__container'>
                {hasPbpWithAvaHmo(locations) && (
                    <ParseAcceptingAVAHmo locations={locations} />
                )}
            </p>
            {isMedicaidRegistered && (
                <p className='icon__container'>
                    <AiFillCheckCircle className='check__icon'/>
                    {' '}
                    {MEDICAID_INDICATOR_TEXT}
                </p>
            )}
        </div>
    )
}

export default AcceptingConditions;