import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectProviderType } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { ButtonContainer } from "../../components";
import ProvidersFiltersForm from '../Forms/ProvidersFiltersForm';
import VisionDentalFiltersForm from '../Forms/VisionDentalFiltersForm';
import './FiltersContainer.scss';
import { providerForm } from '../utils';

const FiltersContainer = () => {
    const providerData = providerSelector;
    const selectedProviderType = useSelector(providerData.selectedProviderType);
    const showResults = useSelector(providerData.showResults);
    const dispatch = useDispatch();
    const selectedUSAState = useSelector(providerData.selectedUSAState);
    const selectedCorevageYear = useSelector(providerData.selectedCoverageYear);

    const handleClearSearch = () => {
        dispatch(selectProviderType(selectedProviderType))
    }

    return (
        <div className='filters-container'>
            {
                providerForm.includes(selectedProviderType) ?
                    <ProvidersFiltersForm selectedProviderType={selectedProviderType} />
                    :
                    <VisionDentalFiltersForm selectedProviderType={selectedProviderType} />
            }

            <ButtonContainer
                className='fieldgroup__clear'
                placeholder='Clear search'
                onClick={handleClearSearch}
                disabled={
                    !(showResults == 2 ||
                        (!providerForm.includes(selectedProviderType)
                        && selectedUSAState
                        && selectedCorevageYear)
                    )
                }
            />
        </div>
    )
}

export default FiltersContainer;