import React from "react";

const ProviderTypesInfo = () => {
    return (
        <>
            <p>
                <strong>1. Doctors: </strong> Select Doctor to view Primary Care Providers (PCP), Specialists & Outpatient Mental Health providers.
            </p>

            <p>
                <strong>2. Facilities: </strong>Hospital, Urgent Care Center, and Skilled Nursing Facility (SNF)
            </p>

            <p>
                <strong>3. Dental: </strong> General Dentist, Endodontist, Oral Surgeon, Orthodontist, Pedodontist, Periodontist, Prosthodontist, and more.
            </p>

            <p>
                <strong>4. Vision: </strong> Optometrist and Ophthalmologist.
            </p>

            <p>
                <strong>5. Other Specialty Services: </strong> Radiology, Dialysis Center, Durable Medical Equipment, Home Health Agency, Sleep medicine, Physical Therapy and more.
            </p>
        </>
    )
}

export default ProviderTypesInfo;