import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchProvider } from "redux/actions";
import { providerSelector } from 'redux/selectors/providerSelectors';
import { mapRequiredSelector } from "../../common/filters";
import { ButtonContainer } from "../../components";
import PlanDisclaimer from "../Disclaimer/PlanDisclaimer";
import CoverageYearField from "../Fields/CoverageYearField";
import DoctorTypeField from "../Fields/DoctorTypeField";
import FacilitiesTypeField from "../Fields/FacilitiesTypeField";
import LocationField from "../Fields/LocationField";
import MedicalGroupField from "../Fields/MedicalGroupField";
import PlanField from "../Fields/PlanField";
import ProviderNameField from "../Fields/ProviderNameField";
import SpecialtiesServicesField from "../Fields/SpecialtiesServicesField";
import { scrollDown } from "../utils";
import './ProvidersFiltersForm.scss';
import { AuditEventTypes, Menus, ProviderType, SubMenus } from "../../common/utils/TrackEventTypes";
import useTrackEvent from "../../common/utils/useTrackEvent";

const ProvidersFiltersForm = ({ selectedProviderType }) => {
    const [showPPODisclaimer, setShowPPODisclaimer] = useState(false);
    const [disabledForm, setDisabledForm] = useState(true);
    const dispatch = useDispatch();

    const providerData = providerSelector;
    const providerType = useSelector(providerData.selectedProviderType);
    const selectedType = useSelector(providerData[mapRequiredSelector[providerType]]);
    const selectedLocation = useSelector(providerData.selectedLocation);
    const selectedCoverageYear = useSelector(providerData.selectedCoverageYear);
    const selectedPlan = useSelector(providerData.selectedPlan);
    const selectedMedicalGroup = useSelector(providerData.selectedMedicalGroup);
    const searchTerm = useSelector(providerData.searchTerm);

    useEffect(() => {
        if (selectedLocation && selectedType && selectedCoverageYear) {
            setDisabledForm(false);
        } else {
            setDisabledForm(true)
        }
    }, [selectedLocation, selectedType, selectedCoverageYear]);

    const trackEvent = useTrackEvent(
        AuditEventTypes.ProviderSearchSearchProviderButtonClicked,
        {
            menu: Menus.GuidedSearch,
            subMenu: SubMenus[providerType as ProviderType],
            searchParameters: {
                selectedType,
                coverageYear: selectedCoverageYear,
                location: selectedLocation,
                selectedPlan,
                selectedMedicalGroup,
                searchTerm,
            }
        }
    );


    const handleSubmit = () => {
        dispatch(searchProvider(2));
        trackEvent();

        scrollDown(1600);
    }

    return (
        <>
            {
                selectedProviderType === 1 && (
                    <DoctorTypeField />
                )
            }

            {
                selectedProviderType === 2 && (
                    <FacilitiesTypeField />
                )
            }

            {
                selectedProviderType === 5 && (
                    <SpecialtiesServicesField />
                )
            }

            <CoverageYearField />
            <LocationField isRequired={true} />
            <PlanField setShowPPODisclaimer={setShowPPODisclaimer} />

            {
                showPPODisclaimer && (
                    <PlanDisclaimer />
                )
            }

            <MedicalGroupField />

            <ProviderNameField selectedProviderType={selectedProviderType} />

            <ButtonContainer
                className='fieldgroup__submit'
                placeholder='Search Provider'
                onClick={handleSubmit}
                disabled={disabledForm}
            />
        </>
    )
}

export default ProvidersFiltersForm;